import { appendPx, getButtonSizes, getColor, lightenColor, styled } from 'imaginarity-react-ui';

export const columnActionWidth: number = 81;
export const columnFlagWidth: number = 190;




export class EditorsSC {
    public static Header = styled.div<{ open?: boolean, bold?: boolean, noHoverEffect?: boolean }>`
        width:100%;
        text-align: left;
        height: ${p => appendPx(getButtonSizes(p.theme, "default").buttonHeight)};
        line-height: ${p => appendPx(getButtonSizes(p.theme, "default").buttonHeight)};
        background: ${p => getColor(p, "@veryLightGrey")};
        color: ${p => p.open ? getColor(p, "@darkerGrey") : getColor(p, "@mainForeground")};
        border-left: 3px solid ${p => getColor(p, "@accent")};
        padding-left: 10px;
        transition: all 0.2s ease-out;
        font-size: 1em;
        font-weight: ${p => p.bold ? "bolder" : 500};
        &:hover{
            background: ${p => p.noHoverEffect ? getColor(p, "@veryLightGrey") : lightenColor(p, "@veryLightGrey", 98)};
        }
`;
    public static LanguageStringEditorSC = {
        container: styled.div<{ hidden?: boolean, widthScaling?: number }>`
            position: relative;
            transition: all 0.2s ease-out;
            /* width: ${p => p.theme.content.width * (p.widthScaling ?? 1.0)}px; */
            width: 100%;
            margin-top: -5px;
            ${p => p.hidden ? "display: none;" : ""}
            button>div{margin:0;}
    `,
        table: styled.div`
            display: table;
            width: 100%;
            border-top: 1px solid ${p => getColor(p, "@middleLightGrey")};
            margin-top: 5px;
            margin-bottom: 0px;
    `,
        th: styled.div<{ topBorder?: boolean }>`
            display: table-cell;
            background-color: ${p => getColor(p, "@mainBackground")};
            border-right: 1px solid ${p => getColor(p, "@middleLightGrey")};
            line-height: 40px;
            text-align: left;
            padding-left: 10px;
            font-weight: bolder;
            border-top: ${p => p.topBorder === true ? `1px solid ${getColor(p, "@middleLightGrey")}` : "none"};
    `,
        tr: styled.div<{ selected: boolean }>`
            display: table-row;
            width: auto;
            height: 40px;
            line-height: 40px;
            border-bottom: 1px solid ${p => getColor(p, "@middleLightGrey")};
            border-left: 1px solid ${p => getColor(p, "@middleLightGrey")};
            background-color: ${p => p.selected ? getColor(p, "fade('@accentBlue', 10%)") : getColor(p, "@mainBackground")};
            transition: all 0.2s ease-out;
            &:hover{
                background: ${p => p.selected ? getColor(p, "fade('@accentBlue', 10%)") : getColor(p, "lighten('@mainBackground', 95%)")};
            }
    `,
        td: styled.div`
            display: table-cell;
            line-height: 40px;
            width: 100%;
            border-right: 1px solid ${p => getColor(p, "@middleLightGrey")};
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            padding: 0 2px 0 10px;
            span{
                margin:0;
                width: 100%;
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
            }
            button>div{margin:0;}
    `,
        subtd: styled.div`

            display: table-cell;
            line-height: 40px;
            width: 100%;
            border-bottom: 1px solid ${p => getColor(p, "@middleLightGrey")};
            border-right: 1px solid ${p => getColor(p, "@middleLightGrey")};
            border-left: 1px solid ${p => getColor(p, "@middleLightGrey")};
            padding: 0 2px 0 10px;
            margin-bottom: 10px;
            background: ${p => getColor(p, "lighten('@mainBackground', 98%)")};
            color: ${p => getColor(p, "@accentBlack")};
            span{
                margin:0;
                width: 100%;
            }
            button>div{margin:0;}
            &:hover{
                background: ${p => getColor(p, "lighten('@mainBackground', 98%)")};
            }
    `,
        tbody: styled.div`
            display: table-row-group;
    `,
        thead: styled.div`
            display: table-header-group;
    `,
        rowGrid: styled.div<{ cols: string }>`
            display: grid;
            display: -ms-grid;

        width: 100%;
            grid-template-columns: ${p => p.cols};
            -ms-grid-columns: ${p => p.cols};
            border: 1px solid ${p => getColor(p, "@middleLightGrey")};
    `,
        rowGridCol: styled.div<{ col: number }>`
            grid-column: ${p => p.col + 1};
            -ms-grid-column: ${p => p.col + 1};
            padding: 1px;
            line-height: 40px;
            height: 40px;
    `,
        resizer: styled.div<{ resizing?: boolean }>`
        right: 0;
        background-color: blue;
        width: 10px;
        height: 100%;
        position: absolute;
        top: 0;
        z-index: 1;
        ${p => p.resizing ? "background: red" : ""};
    `,

        cellProps: (props: any, { cell }: { cell: any }) => EditorsSC.LanguageStringEditorSC.getStyles(props, cell.column.align, cell.column.padding),

        getStyles: (props: any, align = 'left', padding = undefined) => [
            props,
            {
                style: {
                    justifyContent: align === 'right' ? 'flex-end' : 'flex-start',
                    alignItems: 'flex-start',
                    display: 'flex',
                    padding: padding,
                },
            },
        ]
    };

    public static Grid = styled.div<{ shadowed?: boolean, bordered?: boolean }>`
        display: grid;
        width: 100%;
        grid-template-columns: ${p => columnActionWidth + columnFlagWidth}px 1fr;
        line-height: 40px;
        background: ${p => getColor(p, "@veryLightGrey")};
        box-shadow: ${p => p.shadowed ? `0 6px 8px -6px ${getColor(p, "@mainForeground")}` : "none"};
        border: ${p => p.bordered ? `1px solid ${getColor(p, "@middleLightGrey")}` : "none"};
        border-top: none;
        button>div{
            margin: 0;
        }
`;
    public static Styles = styled.div`
        max-width: 100%;
        div{
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
    }
    p {
        line-height: 16px;
        margin-top: 12px; 
    }    
`;
    public static CellContainer = styled.div`
        width: calc(100% - 40px);
        float: left;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
`;
    public static Container = styled.div`
        padding-top: 20px;
        padding-bottom: 150px;
`;
    public static ELCContainer = styled.div`
        display: grid;
        width: 100%;
        grid-template-columns: 160px 1fr;
        line-height: 40px;
`;
    public static ContainerContent = styled.div`
        /* padding: 0px;
        border-left: 1px solid ${p => getColor(p, "@middleLightGrey")};
        border-bottom: 1px solid ${p => getColor(p, "@middleLightGrey")};
        border-right: 1px solid ${p => getColor(p, "@middleLightGrey")};
        transition: all 0.2s ease-out;
        &:hover{
            border-left: 1px solid ${p => getColor(p, "@lightGrey")};
            border-bottom: 1px solid ${p => getColor(p, "@lightGrey")};
            border-right: 1px solid ${p => getColor(p, "@lightGrey")};
            background: ${p => getColor(p, "@veryLightGrey")};
        } */
        padding-top: 5px;
        padding-bottom: 15px;
`;
    public static ContainerBottom = styled.div`
        height: 40px;
        width: 100%;
        //background: ${p => getColor(p, "@veryLightGrey")};
        margin: 10px 0px 10px 0px;
        button>div{
            margin: 0;
        }
`;
    public static G = styled.div`
        display: grid;
        grid-template-columns: 40px 1fr 4fr;
        border-bottom: 1px solid ${p => getColor(p, "@middleLightGrey")}; 
        &:hover{
            background: ${p => getColor(p, "@veryLightGrey")}; 
        }
`;
    public static G1 = styled.div`
        display: grid;
        grid-template-columns: 1fr 40px max-content;
        border-bottom: 1px solid ${p => getColor(p, "@middleLightGrey")}; 
        &:hover{
            background: ${p => getColor(p, "@veryLightGrey")}; 
        }
`;
    public static D = styled.div`
        line-height: 40px;
        padding: 1px;
        height: 40px;
        button>div{margin: 0;}
`;
    public static L = styled.div`
        line-height: 40px;
        padding: 1px;
        padding-left: 10px;
        height: 40px;
`;
    public static ETDGrid = styled.div`
        display: grid;
        grid-template-columns: 190px 190px;
`;

    public static TableInfo = styled.div`
        float: left;
        line-height: 12px;
        font-size: 0.7rem;
        padding-top: 8px;
        padding-left: 10px;
        color: ${p => getColor(p, "@lightGrey")}; 
        padding-right: 20px;
        text-transform: uppercase;
`;

    public static ContentTypeContainer = styled.div<{ hoverable: boolean }>`
        cursor: ${p => p.hoverable ? "pointer" : "default"};
        display: grid;
        width: calc(100% - 20px);
        grid-template-columns: 1fr 20px;
        line-height: 20px;
        transition: all 0.2s ease-out;
        &:hover{
            color: ${p => p.hoverable ? getColor(p, "@accent") : "inherit"}; 
            svg{
                stroke: ${p => p.hoverable ? getColor(p, "@accent") : "inherit"}; 
            }
        }
`;

    public static SubTitle = styled.div`
    display: inline-block;
    margin-left: 10px;
    font-size: 0.9em;
    color: ${p => lightenColor(p, "@veryLightGrey", 40)};
`;

    public static FileIconContainer = styled.div<{ showInfo: boolean }>`
    float: left;
    width: 40px;
    height: 40px;
    margin-left: ${p => p.showInfo ? -10 : -13}px;
    margin-right: ${p => p.showInfo ? -1 : 3}px;
    position: relative;
    background: ${p => p.showInfo ? "transparent" : lightenColor(p, "@veryLightGrey", 97)};
`;
    public static FileIconContainerTrans = styled.div`
    position: absolute;
    top: -4px;
    left: 3px;
    &>div{
        width: 32px;
        height: 32px;
    }
`;
}