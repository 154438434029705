import { fadeColor, getColor, lightenColor, styled } from "imaginarity-react-ui";
import { keyframes } from "styled-components";

const SlitIn = keyframes`
0% {
    -webkit-transform: translateZ(-800px) rotateX(90deg);
            transform: translateZ(-800px) rotateX(90deg);
    opacity: 0;
  }
  54% {
    -webkit-transform: translateZ(-160px) rotateX(87deg);
            transform: translateZ(-160px) rotateX(87deg);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateZ(0) rotateX(0);
            transform: translateZ(0) rotateX(0);
  }
  
`;
const SlideInTop = keyframes`
 0% {
    -webkit-transform: translateY(-40px);
            transform: translateY(-40px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1;
  }
  
`;




export class InfoBoxSC {
  public static InfoBoxContainer = styled.div<{ color: string, hide: boolean, darkMode: boolean, icon: boolean, noBold: boolean, noUppercase: boolean, animated: boolean, noBorder: boolean, noLetterSpacing: boolean }>`
    display: ${p => p.hide ? "none" : "grid"};
    grid-template-columns: ${p => p.icon ? "40px 1fr" : "1fr"};
    grid-template-rows: 1fr;
    width: 100%;
    min-height: 40px;
    line-height: 40px;
    font-size: 0.9rem;
    text-transform: ${p => p.noUppercase ? undefined : "uppercase"};
    letter-spacing: ${p => p.noLetterSpacing ? "unset" : "1.1px"};
    font-weight: ${p => p.noBold ? "normal" : "bolder"};
    color: ${p => p.darkMode ? getColor(p, p.color) : lightenColor(p, p.color, 70)};
    background: ${p => fadeColor(p, p.color, 5)};
    border: ${p => p.noBorder ? "none" : `1px solid ${getColor(p, p.color)}`};
    margin-bottom: 10px;
    button>div{margin: 0;}
    //animation: ${p => p.animated ? SlitIn : ""} 0.45s ease-out both;
    animation: ${p => p.animated ? SlideInTop : ""} 0.2s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;

    button>div{
      svg{
        stroke: ${p => getColor(p, p.color)};
      }
    &:hover{
      svg{
        stroke: ${p => getColor(p, "color")};
      }
    }
    }
    .icon{
        float: left;
        height: 40px;
        width: 40px;
        text-align: center;
        margin-right: 10px;
        background: ${p => fadeColor(p, p.color, 15)};
        svg{
            stroke: ${p => p.darkMode ? getColor(p, p.color) : lightenColor(p, p.color, 70)};
          }
    }
`;
}