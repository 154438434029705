import { Updater } from "@tanstack/react-table";
import { AssetController, CategoryDownloadDto, ContentController, ContentPostContentDownloadDto, ContentPostContentLocationDownloadDto, ContentPostContentTypeDownloadDto, ContentPostDownloadDto, ContentPostMediaTypeDownloadDto, ControllerHelper, ExtendedDataCollection, GroupDownloadDto, MediaDownloadDto, NotificationDownloadDto, PlayListNodeDownloadDto, PlayListNodeUploadDto, PlaylistEdgeTransitionFunction, PlaylistItemStateEnum, PlaylistStateDownloadDto, PlaylistUploadDto, PostDownloadDto, UserController, UserDownloadDto, UserShortInfoDownloadDto, WikiCategoryDownloadDto, WikiTableDownloadDto } from "collaboration-service";
import { DataClassBase, LinkDto, LinkType } from "imaginarity-azure";
import { ImgIcons } from "imaginarity-react-ui";
import _ from "lodash";
import React from "react";
import { RouteParams } from "universal-router";
import { Actions } from "./ApplicationState/Actions";
import { PluginActions } from "./ApplicationState/PluginStateHandling";
import { CurrentSettings } from "./Config";
import { getTranslated } from "./Helpers/TranslationHelpers";
import { ImgI18N, ImgI18NTranslateFunction } from "./ImgI18N";
import Axios, { AxiosRequestConfig } from "axios";

const STRIP_COMMENTS = /((\/\/.*$)|(\/\*[\s\S]*?\*\/))/mg;
const ARGUMENT_NAMES = /([^\s,]+)/g;
function args(func: any) {
    const fnStr = func.toString().replace(STRIP_COMMENTS, '');
    const result = fnStr.slice(fnStr.indexOf('(') + 1, fnStr.indexOf(')')).match(ARGUMENT_NAMES);
    // eslint-disable-next-line
    const rStr = "return\\s*" + result[0] + ".([^;\}]*)";
    const findRet = new RegExp(rStr, "mg");
    const res = findRet.exec(fnStr);
    if (res) {
        const r1 = res[1];
        if (r1.trim() === "")
            return [];
        return res[1].split(".");
    }
    return [];
}

export function getFunctionPath<T>(func: (v: T) => any) {
    return args(func);
}
export function getPath<T>(func1: (v: Required<T>) => any) {
    return args(func1);
}

export function applyPath<T, S>(val: T, func1: (v: Required<T>) => S) {
    const p = getPath(func1);
    let c = val;
    let i = 0;
    while (c && i < p.length)
        c = (c as any)[p[i++]];
    return c as any as (S | undefined);
}

// eslint-disable-next-line
export const URLRegexML: RegExp = /http(s)?:\/\/[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:\/?#[\]@!\$&'\(\)\*\+,;=.]+|http:\/\/localhost:3000\/(index\.html)?#\/.*/g;

export function splitText<P extends { url: string }>(text: string, UrlCard?: React.ComponentClass<P> | React.StatelessComponent<P>, urlParams?: P): JSX.Element[] {
    const delimitter = "#!^!#";
    if (text === undefined)
        return [];

    text = text.replace(/(?:\r\n|\r|\n)/g, delimitter);
    const splits = text.split(delimitter);
    const toRet: JSX.Element[] = [];
    const co = splits.length;
    for (let i = 0; i < co; i++) {
        if (UrlCard) {
            let m: RegExpExecArray | null;
            let j = 0;
            do {
                m = URLRegexML.exec(splits[i]);
                if (m) {
                    let s = splits[i].substr(0, m.index);
                    //                    console.log("1 =>", s);
                    toRet.push(<span key={i + "-" + j + "-1"}>{s}</span>);
                    s = splits[i].substr(m.index, m[0].length);
                    //                    console.log("2 =>", s);
                    const up = { ...urlParams, url: s } as P;
                    toRet.push(<UrlCard key={i + "-" + j + "-url"} {...up} />);
                    s = splits[i].substr(m.index + m[0].length);
                    //                    console.log("3 =>", s);
                    toRet.push(<span key={i + "-" + j + "-3"}>{s}</span>);
                    //                    console.log(m);
                    j++;
                }
            } while (m);
            if (j === 0)
                toRet.push(<span key={i}>{splits[i]}</span>);
        }
        else
            toRet.push(<span key={i}>{splits[i]}</span>);
        if (i < co - 1)
            toRet.push(<br key={"br-" + i} />);
    }
    return toRet;
}

export function splitTextOnlyLastLink<P extends { url: string }>(text: string, UrlCard?: React.ComponentClass<P> | React.StatelessComponent<P>, urlParams?: P): JSX.Element {
    const delimitter = "#!^!#";
    if (text === undefined)
        return {} as JSX.Element;
    text = text.replace(/(?:\r\n|\r|\n)/g, delimitter);
    const splits = text.split(delimitter);
    const toRet: JSX.Element[] = [];
    const co = splits.length;
    for (let i = 0; i < co; i++) {
        if (UrlCard) {
            let m: RegExpExecArray | null;
            let j = 0;
            do {
                m = URLRegexML.exec(splits[i]);
                if (m) {
                    let s = splits[i].substr(0, m.index);
                    s = splits[i].substr(m.index, m[0].length);
                    const up = { ...urlParams, url: s } as P;
                    // if (toRet.length <= 0)
                    //     toRet.pop();
                    toRet.push(<UrlCard key={i + "-" + j + "-url"} {...up} />);
                    s = splits[i].substr(m.index + m[0].length);
                    j++;
                }
            } while (m);
            if (j === 0)
                toRet.push(<span key={i}>{splits[i]}</span>);
        }
        else
            toRet.push(<span key={i}>{splits[i]}</span>);
        if (i < co - 1)
            toRet.push(<br key={"br-" + i} />);
    }
    return toRet[toRet.length - 1];
}

export function splitTextToPara(text: string): JSX.Element[] {
    const delimitter = "#!^!#";
    text = text.replace(/(?:\r\n|\r|\n|\\n)/g, delimitter);
    const splits = _.filter(text.split(delimitter), t => t.length > 0);
    const toRet: JSX.Element[] = [];
    const co = splits.length;
    for (let i = 0; i < co; i++) {
        toRet.push(<p key={i}>{splits[i]}</p>);
    }
    return toRet;
}

export function getLink(links: { links: LinkDto[] } | LinkDto[] | undefined, refName: LinkType): LinkDto | undefined {
    if (_.isArray(links))
        return _.find(links, (link: LinkDto) => link.ref === refName);
    return _.find(links?.links, (link: LinkDto) => link.ref === refName);
}


export function whyDidYouUpdate<PROPS, STATE>(prevProps: PROPS, thisProps: PROPS, prevState: STATE, thisState: STATE, printMark?: (p: PROPS, s: STATE) => void) {
    const pDif = !_.isEqual(thisProps, prevProps);
    const sDif = !_.isEqual(thisState, prevState)
    if (printMark)
        printMark(thisProps, thisState);
    if (pDif) {
        console.log("prevProps => ", _.clone(prevProps))
        console.log("props => ", _.clone(thisProps))
        const res = _.reduce(thisProps as any, function (result, value, key) {
            return _.isEqual(value, (prevProps as any)[key]) ?
                result : result.concat(key as any);
        }, []);
        console.log(res)
    }

    if (sDif) {
        console.log("prevState => ", _.clone(prevState))
        console.log("state => ", _.clone(thisState))
        const res = _.reduce(thisState as any, function (result, value, key) {
            return _.isEqual(value, (prevState as any)[key]) ?
                result : result.concat(key as any);
        }, []);
        console.log(res)
    }
}



export interface TextAndIndices {
    text: string;
    indices: number[];
}

export function mapHtml2TextAndIndices(html: string) {
    let ret = { text: "", indices: [] as number[] };    // TextAndIndices
    let inside = false;
    for (let i = 0; i < html.length; i++) {
        if (html[i] === "<") {
            inside = true;
            continue;
        }
        if (html[i] === ">") {
            inside = false;
            continue;
        }
        if (!inside) {
            ret.text += html[i];
            ret.indices.push(i);
        }
    }
    return ret;
}

// target: html
// search: string to search and mark within target html
// startTag: start html tag to enclose found items
// endTag: end html tag to enclose found items
export function searchHtmlAndMark(target: string, search: string, startTag: string, endTag: string, currentCount: number = 0) {
    search = search.toLowerCase();
    let html = "";
    if (target === undefined) {
        return { html: target, count: 0 };
    }
    const tandi = mapHtml2TextAndIndices(target);

    const toSearchIn = tandi.text.toLowerCase();
    let index: number = toSearchIn.indexOf(search, 0);
    if (index < 0) {
        return { html: target, count: 0 };
    }
    let indexEnd: number;   // end index
    let last: number;       // last found index during loop, to check if there were a gap (due to html tags in incoming html)
    // e.g.: <b>dog<i>is</i>good</b>but<i><b>cat</i>is</b>bad <-- "isgood" and "catis" must also be found although in different html tags
    let count = 1;
    indexEnd = index + search.length - 1;
    html += target.slice(0, tandi.indices[index]);
    do {
        html += startTag.replace(/\{\{pos\}\}/g, `${count - 1 + currentCount}`);

        last = tandi.indices[index];    // cover first find
        for (let i = index; i <= indexEnd; i++) {
            const diff = tandi.indices[i] - last;
            if (diff > 1) {
                html += endTag + target.slice(last + 1, tandi.indices[i]) + startTag.replace(/\{\{pos\}\}/g, `${count - 1 + currentCount}`);   // add what is found between
            }
            last = tandi.indices[i];
            html += tandi.text[i];
        }
        html += endTag;

        // is there more in the string?
        index = toSearchIn.indexOf(search, indexEnd + 1);
        if (index >= 0) {
            count++;
            const chunk = target.slice(tandi.indices[indexEnd] + 1, tandi.indices[index]);
            indexEnd = index + search.length - 1;
            html += chunk;
        }
    } while (index >= 0);

    html += target.slice(tandi.indices[indexEnd] + 1);

    return { count, html };
};
export function searchTableAndMark(target: WikiTableDownloadDto, search: string, startTag: string, endTag: string): { tableNew: WikiTableDownloadDto, currentCount: number } {
    search = search.toLowerCase();

    if (target === undefined) {
        return { tableNew: target, currentCount: 0 };
    }
    const tableNew = _.cloneDeep(target);
    let currentCount = 0;
    _.forEach(tableNew.header, h => {
        const { html, count } = searchHtmlAndMark(h.content, search, startTag, endTag, currentCount);
        h.content = html;
        currentCount += count;
    });
    _.forEach(tableNew.rows, r => _.forEach(r.cells, cell => {
        const { html, count } = searchHtmlAndMark(cell.content, search, startTag, endTag, currentCount);
        cell.content = html;
        currentCount += count;
    }));
    return { tableNew, currentCount };
};

export function generateUUID() { // Public Domain/MIT
    var d = new Date().getTime();//Timestamp
    var d2 = (performance && performance.now && (performance.now() * 1000)) || 0;//Time in microseconds since page-load or 0 if unsupported
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        var r = Math.random() * 16;//random number between 0 and 16
        if (d > 0) {//Use timestamp until depleted
            r = (d + r) % 16 | 0;
            d = Math.floor(d / 16);
        } else {//Use microseconds since page-load if supported
            r = (d2 + r) % 16 | 0;
            d2 = Math.floor(d2 / 16);
        }
        return (c === 'x' ? r : ((r & 0x3) | 0x8)).toString(16);
    });
}
export const template = (tpl: string, args: any) => tpl.replace(/\${(\w+)}/g, (_, v) => args[v]);

export const interpolate = (iString: string, data: any) => {
    try {
        const f = () => {
            // eslint-disable-next-line
            return new Function("return `" + iString + "`;").call(data);
        };
        return f();
    }
    catch {
        return "error in string interpolation";
    }
};

export const miniEval = (formular: string, data: any) => {
    try {
        const f = () => {
            // eslint-disable-next-line
            return new Function("return " + formular + ";").call(data);
        };
        return f();
    }
    catch {
        return "error in string formular";
    }
};








export const visitGroups = (groups: GroupDownloadDto[] | undefined, visitor: (group: GroupDownloadDto, level: number) => boolean, level?: number): boolean => {
    if (!groups || groups.length === 0)
        return true;
    let i = 0;
    while (i < groups.length) {
        if (!visitor(groups[i], level ?? 0))
            return false;
        if (!visitGroups(groups[i].childGroups, visitor, (level ?? 0) + 1))
            return false;
        i++;
    }
    return true;
}

export const visitWikiCats = (cats: WikiCategoryDownloadDto[] | undefined, visitor: (cat: WikiCategoryDownloadDto) => boolean): boolean => {
    if (!cats || cats.length === 0)
        return true;
    let i = 0;
    while (i < cats.length) {
        if (!visitor(cats[i]))
            return false;
        if (!visitWikiCats(cats[i].children, visitor))
            return false;
        i++;
    }
    return true;
}


export const createMediaDownloadDtoFromUri = (uri: string, type?: string, ref?: string): MediaDownloadDto => {
    return {
        changed: false,
        fileName: "created",
        id: "",
        links: [{
            method: "GET",
            ref: ref ?? "self",
            uri,
        }],
        mediaType: type ?? "image/png",
        reference: "",
        thumbnails: [],
        created: new Date(),
        creator: {} as UserShortInfoDownloadDto,
        changedAt: new Date(),
        changedBy: {} as UserShortInfoDownloadDto,
        hasStorageThumbnails: false,
    }
}


export const getGroupsOfTypeWithAnyRole = (user: UserDownloadDto | undefined, groups: GroupDownloadDto[] | undefined, groupTypes: string | string[], roles: string | string[]): GroupDownloadDto[] => {
    const r = _.isArray(roles) ? roles : [roles];
    const gt = _.isArray(groupTypes) ? groupTypes : [groupTypes];

    const toRet: GroupDownloadDto[] = [];
    if (user && groups)
        _.forEach(user.groupRoles, gr => {
            const group = _.find(groups, g => g.id === gr.groupId);
            if (group && _.findIndex(gt, gtt => group?.groupType === gtt) >= 0) {
                if (_.findIndex(gr.roles, ro => _.findIndex(r, rr => rr === ro) >= 0) >= 0)
                    toRet.push(group);
            }
        });
    return toRet;
};

export const getGroupsOfType = (user: UserDownloadDto | undefined, groups: GroupDownloadDto[] | undefined, groupTypes: string | string[]): GroupDownloadDto[] => {
    const gt = _.isArray(groupTypes) ? groupTypes : [groupTypes];

    const toRet: GroupDownloadDto[] = [];
    if (user && groups)
        _.forEach(user.groupRoles, gr => {
            const group = _.find(groups, g => g.id === gr.groupId);
            if (group && _.findIndex(gt, gtt => group?.groupType === gtt) >= 0)
                toRet.push(group);
        });
    return toRet;
};

export const getGroupsWithAnyRole = (user: UserDownloadDto | undefined, groups: GroupDownloadDto[] | undefined, roles: string | string[]): GroupDownloadDto[] => {
    const r = _.isArray(roles) ? roles : [roles];

    const toRet: GroupDownloadDto[] = [];
    if (user && groups)
        _.forEach(user.groupRoles, gr => {
            const group = _.find(groups, g => (g.id === gr.groupId)) as GroupDownloadDto;
            if (group) {
                if (_.findIndex(gr.roles, ro => _.findIndex(r, rr => rr === ro) >= 0) >= 0)
                    toRet.push(group);
            }
        });
    return toRet;
};

export const getGroupsWithAttribute = (groups: GroupDownloadDto[] | undefined, groupAttribute?: string): GroupDownloadDto[] => {
    return _.filter(groups, g => groupAttribute === undefined || (g.attributes !== undefined && g.attributes[groupAttribute] !== undefined))
};



export function getFrontEndSettingFromState<T>(user: UserDownloadDto | undefined, settingName: string): T | undefined {
    if (user === undefined)
        return undefined;

    if (!user.userSettings.frontEndSettings)
        user.userSettings.frontEndSettings = {};
    const val = user.userSettings.frontEndSettings[settingName];
    if (!val)
        return undefined;
    const toRet: T = JSON.parse(val);
    return toRet;
}

export const setFrontEndSetting = async <T extends any>(user: UserDownloadDto | undefined, settingName: string, changer: (old: T | undefined) => T | undefined): Promise<UserDownloadDto | undefined> => {
    if (user === undefined)
        return undefined;

    if (!user.userSettings.frontEndSettings)
        user.userSettings.frontEndSettings = {};
    const val = user.userSettings.frontEndSettings[settingName];
    const toChg: T = val ? JSON.parse(val) : undefined;
    const toSet = changer(toChg);
    user.userSettings.frontEndSettings[settingName] = JSON.stringify(toSet);

    const newUser = await ControllerHelper.singleCall({ id: user.id, settings: user.userSettings }, UserController.PostUserSettings, true);
    return newUser;
}

export function getNotificationText(notification: NotificationDownloadDto): { icon: ImgIcons, text: string } {
    let toRet = { icon: "", text: "" };
    switch (notification.notificationType) {
        case "BookmaredPost":
            toRet = { icon: "bookmark", text: "{{username}} bookmarked your post" };
            break;
        case "UnbookmarkedPost":
            toRet = { icon: "bookmark outline", text: "{{username}} unbookmarked your post" };
            break;
        case "CommentedPost":
            toRet = { icon: "comment", text: "{{username}} added a comment to your post" };
            break;
        case "DeletedPost":
            toRet = { icon: "delete", text: "{{username}} deleted your post" };
            break;
        case "LikedPost":
            toRet = { icon: "heart", text: "{{username}} liked your post" };
            break;
        case "MentionedInPost":
            toRet = { icon: "users", text: "{{username}} mentioned you in a post" };
            break;
        case "ResponsibleForPost":
            toRet = { icon: "users", text: "{{username}} added you as responsible for a post" };
            break;
        case "RemovedResponsibleForPost":
            toRet = { icon: "users", text: "{{username}} removed you as responsible for a post" };
            break;
        case "SubordinateMentionedInPost":
            toRet = { icon: "users", text: "{{username}} was mentioned in a post" };
            break;
        case "SubordinateRemovedMentionedInPost":
            toRet = { icon: "users", text: "{{username}} removed mention in a post" };
            break;
        case "SubordinatePosted":
            toRet = { icon: "monitor", text: "{{username}} posted something" };
            break;
        case "SubordinateResponsibleForPost":
            toRet = { icon: "users", text: "{{username}} was added as responsible for a post" };
            break;
        case "SubordinateRemovedResponsibleForPost":
            toRet = { icon: "users", text: "{{username}} removed you as responsible for a post" };
            break;
        case "UnlikedPost":
            toRet = { icon: "exclamation triangle", text: "{{username}} unliked your post" };
            break;
        case "MentionedInComment":
            toRet = { icon: "exclamation triangle", text: "{{username}} mentioned you in a comment" };
            break;
        case "RemovedMentionedInPost":
            toRet = { icon: "exclamation triangle", text: "{{username}} removed mention in a post" };
            break;
        case "NewPost":
            toRet = { icon: "monitor", text: "{{username}} posted something" };
            break;
        case "ChangedPost":
            toRet = { icon: "edit", text: "{{username}} edited your post" };
            break;
        case "RatedPost":
            toRet = { icon: "monitor", text: "{{username}} rated your post" };
            break;
        case "SubordinateChangedPost":
            toRet = { icon: "edit", text: "{{username}} edited a post" };
            break;
        case "ChangedPostResponsible":
            toRet = { icon: "edit", text: "{{username}} edited a post" };
            break;
        case "ChangedPostMentioned":
            toRet = { icon: "edit", text: "{{username}} edited a post" };
            break;
        case "ChangedPostLiker":
            toRet = { icon: "edit", text: "{{username}} edited a post you liked" };
            break;
        case "ChangedPostCommenter":
            toRet = { icon: "edit", text: "{{username}} edited a post you commented" };
            break;
        case "ChatNewMessage":
            toRet = { icon: "chat", text: "{{username}} posted a new chat message" };
            break;
        case "QuestionPosted":
            toRet = { icon: "question circle", text: "{{username}} posted a new question" };
            break;
        case "ChangedQuestion":
            toRet = { icon: "edit", text: "{{username}} changed a question" };
            break;
        case "SubordinateChangedQuestion":
            toRet = { icon: "edit", text: "{{username}} changed a subordinated question" };
            break;
        case "AnswerPosted":
            toRet = { icon: "exclamation circle", text: "{{username}} posted a new answer" };
            break;
        case "ChangedAnswer":
            toRet = { icon: "edit", text: "{{username}} changed an answer" };
            break;
        case "SubordinateChangedAnswer":
            toRet = { icon: "edit", text: "{{username}} changed a subordinated answer" };
            break;

        case "QuizPosted":
            toRet = { icon: "edit", text: "{{username}} posted a new quiz" };
            break;
        case "ChangedQuiz":
            toRet = { icon: "edit", text: "{{username}} changed a quiz" };
            break;
        case "SubordinateChangedQuiz":
            toRet = { icon: "edit", text: "{{username}} changed a subordinated quiz" };
            break;
        case "GamePosted":
            toRet = { icon: "edit", text: "{{username}} posted a new game" };
            break;
        case "ChangedGame":
            toRet = { icon: "edit", text: "{{username}} changed a game" };
            break;
        case "SubordinateChangedGame":
            toRet = { icon: "edit", text: "{{username}} changed a subordinated game" };
            break;
        case "ExplanationPosted":
            toRet = { icon: "edit", text: "{{username}} postet a new explanation" };
            break;
        case "ChangedExplanation":
            toRet = { icon: "edit", text: "{{username}} changed an explanation" };
            break;
        case "SubordinateChangedExplanation":
            toRet = { icon: "edit", text: "{{username}} changed a subordinated explanation" };
            break;

        default:
            toRet = { icon: "info", text: "{{username}} made a new action to your post" };
            break;
    }
    return toRet as { icon: ImgIcons, text: string };
}

export interface PasswordCheckResult {
    equal: boolean;
    lengthOk: boolean;
    strength: number;
    valid: boolean;
    msg: string;
}

export function getScoreText(score: number, equal: boolean, valid: boolean, t: ImgI18NTranslateFunction) {
    const ok = valid && equal;
    const add = !equal ? " - " + t("passwords are not equal") : "";
    const msg = ["", "very weak", "weak", "ok", "good", "strong"]
    if (!ok) {
        score = Math.min(1, score);
        return t("password is " + msg[score]) + add;
    }
    return "";
}

const pwdRegex1 = /[a-z]+/;
const pwdRegex2 = /[A-Z]+/;
const pwdRegex3 = /[0-9]+/;
const pwdRegex4 = /[^a-zA-Z0-9]+/;

export function checkPasswords(pwd1: string | undefined, pwd2: string | undefined, t: ImgI18NTranslateFunction): PasswordCheckResult {
    let strength1 = 0; // psswd(pwd1).score;
    strength1 += pwd1 && pwdRegex1.test(pwd1) ? 1 : 0;
    strength1 += pwd1 && pwdRegex2.test(pwd1) ? 1 : 0;
    strength1 += pwd1 && pwdRegex3.test(pwd1) ? 1 : 0;
    strength1 += pwd1 && pwdRegex4.test(pwd1) ? 1 : 0;

    let strength2 = 0; // psswd(pwd1).score;
    strength2 += pwd2 && pwdRegex1.test(pwd2) ? 1 : 0;
    strength2 += pwd2 && pwdRegex2.test(pwd2) ? 1 : 0;
    strength2 += pwd2 && pwdRegex3.test(pwd2) ? 1 : 0;
    strength2 += pwd2 && pwdRegex4.test(pwd2) ? 1 : 0;

    const strength = Math.min(strength1, strength2);

    const equal = pwd1 === pwd2;
    const valid = pwd1 !== undefined && pwd1.length >= 12 && strength >= 3; // hasLowerCase + hasUpperCase + hasNonalphas + hasNumber >= 3;
    return ({
        equal,
        lengthOk: pwd1 && pwd1.length >= 12 ? true : false,
        strength,
        valid,
        msg: !equal ? t("passwords are not equal") : (equal && (pwd1?.length ?? 0) < 12) ? t("password is too short") : getScoreText(strength, equal, valid, t)
    });
}

const polarToCartesian = (centerX: number, centerY: number, radius: number, angleInDegrees: number) => {
    const angleInRadians = (angleInDegrees - 90) * Math.PI / 180.0;

    return {
        x: centerX + (radius * Math.cos(angleInRadians)),
        y: centerY + (radius * Math.sin(angleInRadians))
    };
};

export const svgDescribeArc = (x: number, y: number, radius: number, startAngle: number, endAngle: number) => {

    const start = polarToCartesian(x, y, radius, endAngle);
    const end = polarToCartesian(x, y, radius, startAngle);

    const largeArcFlag = endAngle - startAngle <= 180 ? "0" : "1";

    const d = [
        "M", start.x, start.y,
        "A", radius, radius, 0, largeArcFlag, 0, end.x, end.y
    ].join(" ");

    return d;
};



type AccessFunction = (user?: UserDownloadDto, userGroups?: GroupDownloadDto[], adminGroups?: GroupDownloadDto[]) => boolean;
export class AccessHelpers {
    public static isInGroup = (groupTypes: string[] | string, groupAttribute?: string): AccessFunction => (u, ug, ag) => {
        const c = _.isArray(groupTypes) ? groupTypes : [groupTypes];
        return _.findIndex(ug, g => (c.indexOf(g.groupType) >= 0) && (groupAttribute === undefined || (g.attributes !== undefined && g.attributes[groupAttribute] !== undefined))) >= 0;
    }
    public static isAdminSomewhere = (): AccessFunction => (u, ug, ag) => {
        return ag !== undefined && ag.length > 0;
    }
    public static isAdmin = (groupTypes: string[] | string): AccessFunction => (u, ug, ag) => {
        let toRet = false;
        const c = _.isArray(groupTypes) ? groupTypes : [groupTypes];
        visitGroups(ag, g => {
            if (c.indexOf(g.groupType) >= 0) {
                toRet = true;
                return false;
            }
            return true;
        });
        return toRet;
    }

    public static isAdminInGroup = (groupId?: string): AccessFunction => (u, ug, ag) => {
        const id = groupId ?? u?.userSettings?.lastGroupId;
        if (!id)
            return false;
        let toRet = false;
        visitGroups(ag, g => {
            if (id === g.id) {
                toRet = true;
                return false;
            }
            return true;
        });
        return toRet;
    }
    public static isGlobalAdmin = (): AccessFunction => (u, ug, ag) => {
        if (_.find(u?.groupRoles, gr => gr.groupId === "GLOBAL")) {
            return true;
        }
        return false;
    }
}


export type ModuleTypes = "BestPracticePost" | "NewsPost" | "Quiz" | "TubePost" | "SlideShow" | "CommunityFeedPost" | "PorscheMomentPost" | "ContentPost" | "BriefingPost" | "Asset3D" | "WikiArticle" | "Playlist" | "ScormCourse";

export const moduleTypeTitle = (module: ModuleTypes) => {
    switch (module) {
        case "BriefingPost":
            return "SMW Briefing";
        case "CommunityFeedPost":
            return "Community";
        case "ContentPost":
            return "SMW";
        case "NewsPost":
            return "News";
        case "Playlist":
            return "Playlist";
        case "PorscheMomentPost":
            return "excite!";
        case "Quiz":
            return "Quiz";
        case "ScormCourse":
            return "Scorm";
        case "SlideShow":
            return "Slideshow";
        case "TubePost":
            return "Tube";
        case "WikiArticle":
            return "PPE";
        case "BestPracticePost":
            return "PRI";
        case "Asset3D":
            return "Asset 3D";
    }
};
export const moduleTypeIcon = (module: ModuleTypes) => {
    switch (module) {
        case "BriefingPost":
            return "info";
        case "CommunityFeedPost":
            return "feed";
        case "ContentPost":
            return "feed";
        case "NewsPost":
            return "feed";
        case "Playlist":
            return CurrentSettings.showNewGlobalSearchItem ? "education" : "playlist";
        case "PorscheMomentPost":
            return "excite";
        case "Quiz":
            return "game";
        case "ScormCourse":
            return "award";
        case "SlideShow":
            return "slideshow";
        case "TubePost":
            return CurrentSettings.showNewGlobalSearchItem ? "monitor" : "video player";
        case "WikiArticle":
            return CurrentSettings.showNewGlobalSearchItem ? "globe" : "education";
        case "BestPracticePost":
            return "bulb";
        case "Asset3D":
            return "bulb";
    }
};
export const moduleTypeColors = (module: ModuleTypes) => {
    return "rgb(46, 45, 48)";
};
export function getTranslatedValue<T extends { lng?: string }>(values?: T[], lang?: string): T | undefined {
    if (!values)
        return undefined;
    const lng = lang ? lang : ImgI18N.getInstance().currentLanguage;
    if (lng) {
        let tmp = _.find(values, (v: T) => v.lng === lng);

        if (tmp) {
            return tmp;
        } else {
            const langReduced = lng.split('-')[0];
            tmp = _.find(values, (v: T) => v.lng === langReduced);
            if (tmp)
                return tmp;
            tmp = _.find(values, (v: T) => v.lng === "en");
            if (tmp)
                return tmp;
        }
    }
    return values[0] ? values[0] : undefined;
}
export function getTranslatedLinkForContent(contents: ContentPostContentDownloadDto[], lang?: string): string {
    const res = getTranslatedValue(contents, lang);
    if (res) {
        if (res.useContent) {
            const link = _.find(res.content.links, (l: LinkDto) => l.ref === "self");
            if (link)
                return link.uri;
        }
        else
            return res.link;
    }
    return "";
}

export const sortCategories = (cats: CategoryDownloadDto[] | undefined, contentLanguage?: string) => {
    if (!cats)
        return undefined;
    return _.orderBy(_.orderBy(cats, c => getTranslated(c.names, contentLanguage)?.text), c1 => c1.order ?? 0);
}

export const updateCategoriesAndCurrentStack = async (
    dispatch: React.Dispatch<any>,
    groupType: string, mapName: string,
    setCategories: typeof Actions.setTubeCategories,
    setStack: typeof Actions.setTubeCurrentCategoryStack,
    params?: RouteParams,
) => {
    const catid0 = params?.catid as string;
    const catid1 = params?.catid1 as string;
    const catid2 = params?.catid2 as string;
    const catid3 = params?.catid3 as string;
    const catid4 = params?.catid4 as string;
    const catid5 = params?.catid5 as string;

    const cs: string[] = [];
    if (catid0) cs.push(catid0);
    if (catid1) cs.push(catid1);
    if (catid2) cs.push(catid2);
    if (catid3) cs.push(catid3);
    if (catid4) cs.push(catid4);
    if (catid5) cs.push(catid5);
    const categoryDtosRes = await ControllerHelper.singleCall({
        definition: {
            categoryStack: cs,
            groupType,
            mapName
        }
    }, ContentController.GetCategoriesForAllGroupsByGroupTypeMapped);

    dispatch(PluginActions.batchActions([
        setCategories(sortCategories(categoryDtosRes.elements)),
        setStack(categoryDtosRes.data),
    ]));
};

export const canvasToFile = async (canvas: HTMLCanvasElement, name: string) => {
    return new Promise((resolve: (file: File) => void) => {
        canvas.toBlob((blob) => {
            const file: File = Object.assign(blob as any, { name, lastModifiedData: new Date() });
            resolve(file);
        }, 'image/png', 0.95);
    });
}

export function shallowCompare(newObj: any, prevObj: any) {
    for (let key in newObj)
        if (newObj[key] !== prevObj[key]) return false;
    return true;
}

export const initPostDownloadDto = (groupId: string, type: string | undefined, id?: string) => {
    let post: PostDownloadDto = {
        id: id ?? "",
        headlines: [],
        descriptions: [],
        media: {} as MediaDownloadDto,
        commentCount: 0,
        ratingCount: 0,
        rating: 0,
        likeCount: 0,
        didLike: false,
        group_id: groupId,
        creator: {} as UserShortInfoDownloadDto,
        categories: [],
        isBookmarked: false,
        created: new Date().toISOString() as any,
        changed: new Date().toISOString() as any,
        changedBy: {} as UserShortInfoDownloadDto,
        tags: [],
        languageTags: [],
        isSticky: false,
        stickyHours: 0,
        wasInNews: false,
        locked: false,
        links: [],
        type: type ?? "PostDownloadDto",
        isReference: false,
        hasChildren: false,
    };
    if (type) {
        post.type = type;
        switch (type) {
            case "ContentPost": {
                const cp: ContentPostDownloadDto =
                {
                    ...post,
                    content: [],
                    contentType: {} as ContentPostContentTypeDownloadDto,
                    mediaType: {} as ContentPostMediaTypeDownloadDto,
                    location: {} as ContentPostContentLocationDownloadDto,
                    volume: 0,
                    volumeType: "",
                }
                post = cp;
            }
        }
    }
    return post;
}


export const copyToOwnAsset = async (media: MediaDownloadDto) => {
    const asset = await ControllerHelper.singleCall({
        data: {
            media: {
                ...media,
                copyByReference: true,
                changed: true,
            },
            tags: [],
            referenceId: "",
        }
    }, AssetController.AddAsset);
    return asset;
}


export type KeyOfType<T, V> = keyof {
    [P in keyof T as T[P] extends V ? P : never]: any
}

export type KeyOfTypeWithOptionals<T, V> = keyof {
    [P in keyof T as T[P] extends V | undefined ? P : never]: any;
}

const convertMsToDays = (ms: number) => {
    const msInOneSecond = 1000
    const secondsInOneMinute = 60
    const minutesInOneHour = 60
    const hoursInOneDay = 24
    const minutesInOneDay = hoursInOneDay * minutesInOneHour
    const secondsInOneDay = secondsInOneMinute * minutesInOneDay
    const msInOneDay = msInOneSecond * secondsInOneDay
    return Math.ceil(ms / msInOneDay)
}
export const getDaysBetweenDates = (dateOne: Date, dateTwo: Date) => {
    let differenceInMs = dateTwo.getTime() - dateOne.getTime()
    if (differenceInMs < 0) {
        differenceInMs = dateOne.getTime() - dateTwo.getTime()
    }
    return convertMsToDays(differenceInMs)
}

export function stripHtml(html: string) {
    const tmp = document.createElement("DIV");
    tmp.innerHTML = html;
    return tmp.textContent ?? tmp.innerText ?? "";
}

export const reducerBatchJob = <State extends any, Actions extends any>(state: State, actions: Actions[], reducer: (state: State, action: Actions) => State) => {
    let s = state;
    _.forEach(actions, a => s = reducer(s, a));
    return s;
}

export const ignoreUndefinedCustomizer: _.IsEqualCustomizer = (a, b, c, d, e, f) => {
    return JSON.stringify(a) === JSON.stringify(b);
}

export const reducerSetIfChanged = <Value extends any, State extends object>(state: State, stateKey: KeyOfType<State, Value>, value: Value, onChange?: (state: State, value: Value,) => State, customizer?: _.IsEqualCustomizer): State => {
    if (customizer ? !_.isEqualWith(value, state[stateKey], customizer) : !_.isEqual(value, state[stateKey])) {
        const toRet = {
            ...state,
            [stateKey]: value
        };
        if (onChange)
            return onChange(toRet, value);
        return toRet;
    }
    return state;
}

export function createReducerSetIfChanged<MainState extends object, State extends object>(selector: (s: MainState) => State, fillState: (s: MainState) => MainState) {
    function method<Value extends any>(state: MainState, stateKey: KeyOfType<State, Value>, value: Value, customizer?: _.IsEqualCustomizer): MainState {
        const s: State = selector(state);
        if (customizer ? !_.isEqualWith(value, s[stateKey], customizer) : !_.isEqual(value, s[stateKey])) {
            const toRet = fillState(state);
            const sub = selector(toRet);
            sub[stateKey] = value as any;
            return toRet;
        }
        return state;
    }
    return method;
}


export const reducerUpdater = <Value extends any, State extends object>(state: State, stateKey: KeyOfType<State, Value>, updater: Updater<Value>, onChange?: (state: State, value: Value) => State): State => {
    if (typeof updater === "function") {
        const value = (updater as any)(state[stateKey]);
        return reducerSetIfChanged(state, stateKey, value, onChange);
    }
    else
        return reducerSetIfChanged(state, stateKey, updater, onChange);
}

export const mapExtendedDataCollection = <Target extends DataClassBase, Source extends DataClassBase>(old?: ExtendedDataCollection<Source, string>): ExtendedDataCollection<Target, string> | undefined => {
    return old ? { elements: old.elements as any as Target[], data: old.data, links: old.links } : undefined;
}

export const zeroPad = (num: number, places: number) => String(num).padStart(places, '0');

export const calcMinsInHoursAndMins = (v: number, t: ImgI18NTranslateFunction) => {
    const mins = v % 60;
    const hours = Math.floor(v / 60);
    const minutes = zeroPad(mins, 2);
    const toRet = hours > 0 ? t("{{hours}}:{{minutes}} h", { hours, minutes }) : t("{{minutes}} mins", { minutes: mins });
    return toRet;
};

export const calcMinsInDaysAndHourAndMins = (value: number | undefined, hpd: number) => {
    const d = value ? Math.floor(value / (hpd * 60)) : 0;
    const h = value ? Math.floor((value - (d * hpd * 60)) / 60) : 0;
    const m = value ? (value - (d * hpd * 60)) - (h * 60) : 0;
    const toRet = [d, h, m]
    return toRet;
};

export const calculateDateDifferenceFromDateString = (dateString: string) => {
    const givenDate = new Date(dateString);
    const today = new Date();
    const givenTimestamp = givenDate.getTime();
    const todayTimestamp = today.getTime();
    const differenceInMs = givenTimestamp - todayTimestamp;
    const msPerDay = 1000 * 60 * 60 * 24;
    const differenceInDays = Math.floor(differenceInMs / msPerDay);
    return differenceInDays;
};

export function isChangedDateGreaterThanCreatedDateWithoutTime(created: Date, changed: Date) {
    const createdDateStr = created.toString().split('T')[0];
    const changedDateStr = changed.toString().split('T')[0];
    return changedDateStr > createdDateStr;
}

// eslint-disable-next-line
//export const fileNameRegExp = /^[\w\-. \(\)\[\]#&\$]+$/i;
export const fileNameRegExp = /^[^/\\\n\r]*$/i;

export const visit = <T extends object>(start: T | undefined, goOn: (cur: T) => T[], visitor: (cur: T) => void) => {
    if (!start)
        return;
    visitor(start);
    const child = goOn(start);
    _.forEach(child, c => visit(c, goOn, visitor));
}

export type ArrayElement<ArrayType extends readonly unknown[]> =
    ArrayType extends readonly (infer ElementType)[] ? ElementType : never;


export function convertMinutesToDaysHoursMinutes(totalMinutes: number, t: ImgI18NTranslateFunction, shortNames?: boolean, exact: boolean = true) {
    const minutesInHour = 60;
    const roundUpHoursFromXminutes = 30;
    const hoursInDay = 8;
    const totalHours = totalMinutes / minutesInHour;
    const days = Math.floor(totalHours / hoursInDay);
    const remainingHours = Math.floor((totalHours % hoursInDay) * (minutesInHour / minutesInHour));
    const remainingMinutes = totalMinutes % minutesInHour;

    if (!exact && totalMinutes > 480) {
        let roundedRemainingHours = remainingHours;
        if (remainingMinutes >= roundUpHoursFromXminutes) {
            roundedRemainingHours += 1;
        }
        return <>
            {days > 0 && <span style={{ paddingLeft: 0 }}>{t("{{count}} {{d}}", { count: days, d: shortNames ? "d" : "days" })}</span>}
            {roundedRemainingHours > 0 && <span style={{ paddingLeft: days > 0 ? 5 : 0 }}>{t("{{count}} {{h}}", { count: roundedRemainingHours, h: shortNames ? "h" : "hours" })}</span>}
        </>;
    }
    return <>
        {days > 0 && <span style={{ paddingLeft: 0 }}>{t("{{count}} {{d}}", { count: days, d: shortNames ? "d" : "days" })}</span>}
        {remainingHours > 0 && <span style={{ paddingLeft: days > 0 ? 5 : 0 }}>{t("{{count}} {{h}}", { count: remainingHours, h: shortNames ? "h" : "hours" })}</span>}
        {remainingMinutes > 0 && <span style={{ paddingLeft: (days > 0 || remainingHours > 0) ? 5 : 0 }}>{t("{{count}} {{m}}", { count: remainingMinutes, m: shortNames ? "min" : "minutes" })}</span>}
    </>;
}

export function countElementUsageInNodes(nodes: PlayListNodeUploadDto[], elementId: string): number {
    return nodes.reduce((count, node) => {
        return count + (node.referenceIds?.includes(elementId) ? 1 : 0);
    }, 0);
}

export function getNodeInfo(t: ImgI18NTranslateFunction, transitionFunction: PlaylistEdgeTransitionFunction, referenceContentId?: string, referenceValue?: number, posts?: PostDownloadDto[], lng?: string): string {
    if (transitionFunction === "PercentageDone") {
        const x = referenceValue ? referenceValue * 100 : 0;
        return t("the next phase will be unlocked once at least <b>{{percent}}%</b> of the elements within this phase have been completed", { percent: x })
    }
    else if (transitionFunction === "AbsCountDone") {
        return t("the next phase will become available once a minimum of <b>{{count}} elements</b> within this phase have been completed", { count: referenceValue })
    }
    else if (transitionFunction === "ReferenceDone" || transitionFunction === "ReferenceDoneWithValue") {
        const ref = _.find(posts, p => p.id === referenceContentId);
        let ele = getTranslated(ref?.headlines, lng)?.text;
        if (ele)
            ele = ele.replace(/<\/?p>/g, '');
        let text = t("the next phase will be unlocked when element <b>{{ele}}</b> is marked as completed", { ele });

        if (transitionFunction === "ReferenceDoneWithValue") {
            text += t(" or reaches <b>{{percent}}%</b> progress", { percent: referenceValue ?? 1 * 100 });
        }
        return text;
    } else {
        return "Unknown transition function";
    }
}

export const getPlaylistNodeDueDate = (ps: PlaylistStateDownloadDto, p: PlayListNodeDownloadDto): Date | undefined => {
    const plAD = new Date(ps.assignmentDate);
    // BUG!! muss node assignment date sein!!
    const nAD = new Date(ps.assignmentDate);
    const nfsD = new Date(ps.assignmentDate);
    if (p.dueDateRelativeTo) {
        switch (p.dueDateRelativeTo) {
            case "Absolute":
                return p.absDueDate ? new Date(p.absDueDate) : undefined;
            case "NodeAssignment":
                nAD.setHours(nAD.getHours() + (p.dueDateRelativeInHours ?? 0));
                return nAD;
            case "PlaylistAssignment":
                plAD.setHours(plAD.getHours() + (p.dueDateRelativeInHours ?? 0));
                return plAD;
            case "NodeFirstItemStart":
                nfsD.setHours(nfsD.getHours() + (p.dueDateRelativeInHours ?? 0));
                return nfsD;
        }
    }

    return undefined;
}

export const getPlaylistStateDueDate = (p: PlaylistStateDownloadDto): Date | undefined => {

    const nodes = _.union(p.curNodes, p.nextNodes);
    const dates = _.map(nodes, n => getPlaylistNodeDueDate(p, n));
    // console.log(dates);
    return _.max(dates);
}

export function getHighestAbsDueDate(pState: PlaylistStateDownloadDto): Date | undefined {
    if (pState.nextNodes && pState.nextNodes.length > 0) {
        let highestDueDate: Date | undefined = undefined;

        for (const node of pState.nextNodes) {
            if (node.absDueDate) {
                if (!highestDueDate || node.absDueDate > highestDueDate) {
                    highestDueDate = node.absDueDate;
                }
            }
        }
        return highestDueDate;
    }
    if (pState.curNodes && pState.curNodes.length > 0) {
        let highestDueDate: Date | undefined = undefined;

        for (const node of pState.curNodes) {
            if (node.absDueDate) {
                if (!highestDueDate || node.absDueDate > highestDueDate) {
                    highestDueDate = node.absDueDate;
                }
            }
        }
        return highestDueDate;
    }
    return undefined;
}

export function getHighestDueDateRelativeInHours(pState: PlaylistStateDownloadDto): Date | undefined {
    let highestDate: Date | undefined;

    if (pState.nextNodes && pState.nextNodes.length > 0) {
        for (const node of pState.nextNodes) {
            if (node.dueDateRelativeInHours && node.dueDateRelativeTo) {
                if (
                    node.dueDateRelativeTo === "NodeAssignment" ||
                    node.dueDateRelativeTo === "PlaylistAssignment" ||
                    node.dueDateRelativeTo === "NodeFirstItemStart"
                ) {
                    const hoursToAdd = node.dueDateRelativeInHours;
                    const dateToAddTo = node.dueDateRelativeTo === "NodeAssignment"
                        ? pState.assignmentDate
                        : node.dueDateRelativeTo === "PlaylistAssignment"
                            ? pState.assignmentDate
                            : new Date();

                    const calculatedDate = new Date(dateToAddTo);
                    calculatedDate.setHours(calculatedDate.getHours() + hoursToAdd);

                    if (!highestDate || calculatedDate > highestDate) {
                        highestDate = calculatedDate;
                    }
                }
            }
        }
    }

    if (highestDate) {
        return highestDate;
    }

    if (pState.curNodes && pState.curNodes.length > 0) {
        for (const node of pState.curNodes) {
            if (node.dueDateRelativeInHours && node.dueDateRelativeTo) {
                if (
                    node.dueDateRelativeTo === "NodeAssignment" ||
                    node.dueDateRelativeTo === "PlaylistAssignment" ||
                    node.dueDateRelativeTo === "NodeFirstItemStart"
                ) {
                    const hoursToAdd = node.dueDateRelativeInHours;
                    const dateToAddTo = node.dueDateRelativeTo === "NodeAssignment"
                        ? pState.assignmentDate
                        : node.dueDateRelativeTo === "PlaylistAssignment"
                            ? pState.assignmentDate
                            : new Date();

                    const calculatedDate = new Date(dateToAddTo);
                    calculatedDate.setHours(calculatedDate.getHours() + hoursToAdd);

                    if (!highestDate || calculatedDate > highestDate) {
                        highestDate = calculatedDate;
                    }
                }
            }
        }
    }
    return highestDate;
}

export function getHigherDate(d1: Date | undefined, d2: Date | undefined) {
    if (d1 && d2) {
        const date1 = new Date(d1);
        const date2 = new Date(d2);
        return date1 > date2 ? d1 : d2;
    }
    if (d1) {
        return d1;
    }
    if (d2) {
        return d2;
    }
    return undefined;
}

export function addHoursToSpecificDate(h: number, date: Date) {
    const res = new Date(date);
    res.setHours(res.getHours() + h);
    return res;
}

export function addHoursToISODate(isoDate: Date, hoursToAdd: number) {
    const originalDate = new Date(isoDate);
    const newDate = new Date(originalDate.getTime() + hoursToAdd * 60 * 60 * 1000);
    const date = newDate.toISOString();
    return date;
}

export interface DueInfo {
    icon: string;
    text: string;
    color: string;
    dueToDate?: Date;
}

export type DueToType = "absolute" | "relative" | undefined;

export function calculateDueInfo(dueToType: DueToType, state: PlaylistItemStateEnum, assignmentDate?: Date, absoluteDueDate?: Date, dueDateRelativeInHours?: number, isOptional?: boolean, warning?: boolean): DueInfo {
    let dueToDate: Date | undefined;

    if (dueToType === "absolute" && absoluteDueDate !== undefined) {
        dueToDate = new Date(absoluteDueDate);
    } else if (dueToType === "relative" && assignmentDate !== undefined) {
        const dueDateRelative = new Date(assignmentDate);
        dueDateRelative.setHours(dueDateRelative.getHours() + (dueDateRelativeInHours || 0));
        dueToDate = new Date(dueDateRelative);
    }

    const today = new Date();
    today.setHours(0, 0, 0, 0);

    let icon: string, text: string, color: string;

    if (isOptional === true) {
        icon = "empty";
        text = "optional";
        color = "inherit";
    }
    else {
        if (state === "Finished") {
            icon = "check circle";
            text = "100%";
            color = "@accentGreen";
        } else if (state === "NotAvailable") {
            icon = "times";
            text = "n/v";
            color = "@accentRed";
        } else if ((state === "Started" || state === "Available") && dueToDate && dueToDate >= today) {
            icon = "clock";
            text = warning ? "warning" : "on Track";
            color = "@accentBlack";
        } else if ((state === "Started" || state === "Available") && dueToDate && dueToDate < today) {
            icon = "exclamation circle";
            text = "overdue";
            color = "@accentRed";
        } else {
            icon = "clock";
            text = "open";
            color = "@darkGrey";
        }
    }

    return { icon, text, color, dueToDate };
}

export function getTransitionMessage(t: ImgI18NTranslateFunction, transition: PlaylistEdgeTransitionFunction, hasNextNodes: boolean, playlistIsFinished: boolean, nodeIsFinished: boolean, referenceValue?: number, itemsCount?: number, referenceContentId?: string): undefined | string {
    if (playlistIsFinished || nodeIsFinished) {
        return undefined;
    }
    else {
        switch (transition) {
            case "FallThru":
                return undefined;
            case "PercentageDone":
                return referenceValue && referenceValue > 0
                    ? hasNextNodes
                        ? t("complete at least {{percent}}% of the playlist modules to proceed to the next phase", { percent: referenceValue * 100 })
                        : t("complete at least {{percent}}% of the playlist modules to finish the playlist", { percent: referenceValue * 100 })
                    : undefined;
            case "AbsCountDone":
                return referenceValue && referenceValue > 0
                    ? hasNextNodes
                        ? t("complete {{min}} out of {{max}} playlist module(s) to proceed to the next phase", { min: referenceValue, max: itemsCount })
                        : t("complete {{min}} out of {{max}} playlist module(s) to finish the playlist", { min: referenceValue, max: itemsCount })
                    : undefined;
            // case "ReferenceDone":
            //     return referenceContentId !== undefined
            //         ? hasNextNodes
            //             ? t("complete the required playlist module(s) to proceed to the next phase")
            //             : t("complete the required playlist module(s) to finish the playlist")
            //         : undefined;
            case "ReferenceDone":
                return hasNextNodes
                    ? t("complete the required playlist module(s) to proceed to the next phase")
                    : t("complete the required playlist module(s) to finish the playlist")
                    ;
            case "ReferenceDoneWithValue":
                return referenceValue && referenceValue > 0 && referenceContentId !== undefined
                    ? hasNextNodes
                        ? t("complete the required playlist module(s) with at least {{percent}}% to proceed to the next phase", { percent: referenceValue * 100 })
                        : t("complete the required playlist module(s) with at least {{percent}}% finish the playlist", { percent: referenceValue * 100 })
                    : undefined;
            default:
                return undefined;
        }
    }
}

export function countPlaylistItemsToBeDone(nodes: PlayListNodeDownloadDto[]) {
    let count = 0;
    if (nodes && Array.isArray(nodes)) {
        for (const node of nodes) {
            if (_.first(node.children)?.transitionFunction === "FallThru" && Array.isArray(node.referenceIds)) {
                count += 0;
            }
            if (_.first(node.children)?.transitionFunction === "AbsCountDone" && _.first(node.children)?.referenceValue && Array.isArray(node.referenceIds)) {
                count += _.first(node.children)?.referenceValue ?? 0;
            }
            if ((_.first(node.children)?.transitionFunction === "ReferenceDone" || _.first(node.children)?.transitionFunction === "ReferenceDoneWithValue") && Array.isArray(node.referenceIds)) {
                const contentIds = node.children ? _.first(node.children)?.referenceContentIds?.length : 0;
                count += contentIds ?? 0;
            }
            if (_.first(node.children)?.transitionFunction === "PercentageDone" && Array.isArray(node.referenceIds)) {
                count += Math.ceil(node.referenceIds.length * (_.first(node.children)?.referenceValue ?? 0));
            }
        }
    }
    return count;
}

export interface CounterInfo {
    count: number;
    referenceContentIds?: string[];
}

export function countNodeItemsToBeDone(node: PlayListNodeDownloadDto): CounterInfo {
    let count = 0;
    let referenceContentIds: string[] | undefined = [];
    const ele = _.first(node.children);
    if (ele?.transitionFunction === "FallThru" && Array.isArray(node.referenceIds)) {
        count += 0;
    }
    if (ele?.transitionFunction === "AbsCountDone" && ele?.referenceValue && Array.isArray(node.referenceIds)) {
        count += _.first(node.children)?.referenceValue ?? 0;
    }
    if ((ele?.transitionFunction === "ReferenceDone" || ele?.transitionFunction === "ReferenceDoneWithValue") && Array.isArray(node.referenceIds)) {
        // count += 1;
        const contentIds = node.children ? _.first(node.children)?.referenceContentIds?.length : 0;
        count += contentIds ?? 0;
        referenceContentIds = ele?.referenceContentIds;
    }
    if (ele?.transitionFunction === "PercentageDone" && Array.isArray(node.referenceIds)) {
        count += Math.ceil(node.referenceIds.length * (ele?.referenceValue ?? 0));
    }

    return { count, referenceContentIds };
}

export function countNodeItemsToBeDonePerNode(pState: PlaylistStateDownloadDto, nodes: PlayListNodeDownloadDto[]): number {
    let total = 0;
    _.forEach(nodes, (n, i) => {
        const criticalInfo: CounterInfo = countNodeItemsToBeDone(n);
        let count = 0;
        _.forEach(n.referenceIds, refId => {
            const found = _.find(pState.itemStates, iS => iS.referenceId === refId && iS.state === 'Finished') ??
                _.find(pState.prevItemStates, iS => iS.referenceId === refId && iS.state === 'Finished');

            if (found !== undefined && criticalInfo.referenceContentIds?.length === 0) {
                count++;
            } else if (found !== undefined && criticalInfo.referenceContentIds?.length !== 0 && _.find(criticalInfo.referenceContentIds, rid => rid === refId) !== undefined) {
                count++;
            }

            if (count === criticalInfo.count) {
                return false;
            }
        });
        total += count;
    });

    return total;
};


export function getCriticalAndPendingCounts(pState: PlaylistStateDownloadDto): { criticalCount: number; itemsToBeDone: number } {
    const prevNodes = pState.prevNodes ?? [];
    const curNodes = pState.curNodes ?? [];
    const nextNodes = pState.nextNodes ?? [];

    let criticalCount = 0;
    criticalCount += countNodeItemsToBeDonePerNode(pState, prevNodes);
    criticalCount += countNodeItemsToBeDonePerNode(pState, curNodes);
    criticalCount += countNodeItemsToBeDonePerNode(pState, nextNodes);

    const isAllAtOnce = pState && pState.playlist ? (pState.playlist as PlaylistUploadDto).sequencingMode === "AllAtOnce" : false;
    const AAOcontents = pState && (pState.playlist as PlaylistUploadDto).contents;

    const prevNodesReferenceIdsCount = countPlaylistItemsToBeDone(prevNodes);
    const curNodesReferenceIdsCount = countPlaylistItemsToBeDone(curNodes);
    const nextNodesReferenceIdsCount = countPlaylistItemsToBeDone(nextNodes);
    const allNodesReferenceIdsCount = curNodesReferenceIdsCount + prevNodesReferenceIdsCount + nextNodesReferenceIdsCount;

    const itemsToBeDone = isAllAtOnce ? (AAOcontents?.length ?? 0) : (allNodesReferenceIdsCount ?? 0);

    return { criticalCount, itemsToBeDone };
}



export function countNodeItemsToBeDoneSingleNode(pState: PlaylistStateDownloadDto, node: PlayListNodeDownloadDto): number {
    let total = 0;
    const criticalInfo: CounterInfo = countNodeItemsToBeDone(node);
    let count = 0;
    _.forEach(node.referenceIds, refId => {
        const found = _.find(pState.itemStates, iS => iS.referenceId === refId && iS.state === 'Finished') ??
            _.find(pState.prevItemStates, iS => iS.referenceId === refId && iS.state === 'Finished');

        if (found !== undefined && criticalInfo.referenceContentIds?.length === 0) {
            count++;
        } else if (found !== undefined && criticalInfo.referenceContentIds?.length !== 0 && _.find(criticalInfo.referenceContentIds, rid => rid === refId) !== undefined) {
            count++;
        }

        if (count === criticalInfo.count) {
            return false;
        }
    });
    total += count;

    return total;
};


export const getProgressStatus = (node: PlayListNodeDownloadDto, p: PlaylistStateDownloadDto) => {
    if (node && node.dueDateRelativeTo !== undefined) {
        if (p.state === "Finished") {
            return "done";
        }
        if (playlistIsOverdue(p, node)) {
            return "overdue";
        }
        if (playlistIsWarning(p, node)) {
            return "warning";
        }
    }
    return "on Track";
}

const playlistIsOverdue = (ps: PlaylistStateDownloadDto, node: PlayListNodeDownloadDto) => {
    const dueDate = getPlaylistNodeDueDate(ps, node);
    const now = new Date();

    if (!dueDate)
        return false;
    return dueDate < now;
};
const playlistIsWarning = (ps: PlaylistStateDownloadDto, node: PlayListNodeDownloadDto) => {
    const dueDate = getPlaylistNodeDueDate(ps, node);
    const now = new Date();

    if (dueDate && dueDate > now && dueDate < new Date(now.getTime() + 48 * 60 * 60 * 1000))
        return true;
    return false;
};

export function sortReferenceIdsInNodes(nodes: PlayListNodeDownloadDto[]): PlayListNodeDownloadDto[] {
    nodes.forEach(node => {
        if (node.children) {
            const prioritizedIds = node.children
                .filter(child =>
                    (child.transitionFunction === "ReferenceDone" || child.transitionFunction === "ReferenceDoneWithValue") && child.referenceContentId !== undefined && node.referenceIds?.includes(child.referenceContentId)
                )
                .map(child => child.referenceContentId!)
                .filter((id): id is string => id !== undefined);

            if (prioritizedIds.length > 0) {
                if (!node.referenceIds) {
                    node.referenceIds = [];
                }
                const remainingIds = node.referenceIds.filter(id => !prioritizedIds.includes(id));
                node.referenceIds = [...prioritizedIds, ...remainingIds];
            }
        }
    });
    return nodes;
}

export function removeHtmlTags(str: string) {
    return str.replace(/<\/?[^>]+(>|$)/g, "");
}

export function useWindowHeight() {
    const [windowHeight, setWindowHeight] = React.useState(window.innerHeight);
    React.useEffect(() => {
        const handleResize = () => {
            setWindowHeight(window.innerHeight);
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return windowHeight;
}

export const wait = (ms = 1000) => {
    return new Promise(resolve => {
        console.log(`waiting ${ms} ms...`);
        setTimeout(resolve, ms);
    });
}

export const poll = async (checkForFinish: () => boolean, ms: number, initialWaitMs: number = 0) => {
    if (initialWaitMs > 0)
        await wait(initialWaitMs)
    let result = checkForFinish();
    while (!result) {
        await wait(ms);
        result = checkForFinish();
    }
}
export const pollAsyncCheck = async (checkForFinish: () => Promise<boolean>, ms: number, initialWaitMs: number = 0) => {
    if (initialWaitMs > 0)
        await wait(initialWaitMs)
    let result = await checkForFinish();
    while (!result) {
        await wait(ms);
        result = await checkForFinish();
    }
    return result;
}

export const imageExists = (image_url: string) => {
    try {
        var http = new XMLHttpRequest();
        http.open('HEAD', image_url, false);
        http.send();
        return http.status !== 404;
    }
    catch {
        return false;
    }
}

export const stopAllMedia = () => {
    window.dispatchEvent(new Event('stopAllAudio'));
    const mediaElements = document.querySelectorAll<HTMLMediaElement>('video, audio');
    mediaElements.forEach((mediaElement) => {
        mediaElement.pause();
    });
};


export const isFromIosHomeScreen = () => {
    return (navigator as any).standalone === true;
};


export interface ProgressData {
    loaded?: number;
    lengthComputable: boolean;
    total?: number;
}

export type ProgressCallback = (progress: ProgressData) => void;

// export const useUrlPreload = (givenUrl: string | string[]): { imageUrls: string[], loading: boolean } => {
//     const [result, setResult] = React.useState<{ imageUrls: string[], loading: boolean }>({ imageUrls: [], loading: true });

//     React.useEffect(() => {
//         const l = async () => {
//             if (givenUrl) {
//                 const headers =
//                 {
//                     "X-ZUMO-AUTH": undefined,
//                     "X-Forwarded-Ssl": "on"
//                 };
//                 const all = _.isArray(givenUrl) ? givenUrl : [givenUrl];
//                 const aConfigs: AxiosRequestConfig[] = _.map(all, a =>
//                 ({
//                     url: a,
//                     method: "GET",
//                     headers,
//                     responseType: "blob",
//                 }));
//                 const promises = _.map(aConfigs, async cfg => {
//                     try {
//                         const response = await Axios(cfg)
//                         return response.status === 200 ? URL.createObjectURL(response.data) : undefined;
//                     }
//                     catch (error) {
//                         return undefined;
//                     }
//                 });
//                 setResult({ imageUrls: [], loading: true });
//                 const res = await Promise.all(promises);
//                 setResult({ imageUrls: _.compact(res), loading: false });
//             }
//             else
//                 setResult({ imageUrls: [], loading: false });
//         }
//         l();
//     }, [givenUrl]);
//     return result;
// }

export const useUrlPreload = (givenUrl: string | string[]): { imageUrls: string[], loading: boolean } => {
    const [result, setResult] = React.useState<{ imageUrls: string[], loading: boolean }>({ imageUrls: [], loading: true });

    const cacheRef = React.useRef<Record<string, string>>({});

    React.useEffect(() => {
        const loadImages = async () => {
            if (givenUrl) {
                const urls = Array.isArray(givenUrl) ? givenUrl : [givenUrl];

                const uncachedUrls = urls.filter((url) => !cacheRef.current[url]);

                if (uncachedUrls.length === 0) {
                    setResult({ imageUrls: urls.map((url) => cacheRef.current[url]), loading: false });
                    return;
                }

                setResult((prev) => ({ ...prev, loading: true }));

                const headers = {
                    "X-ZUMO-AUTH": undefined,
                    "X-Forwarded-Ssl": "on",
                };

                const aConfigs: AxiosRequestConfig[] = uncachedUrls.map((url) => ({
                    url,
                    method: "GET",
                    headers,
                    responseType: "blob",
                }));

                await Promise.all(
                    aConfigs.map(async (cfg) => {
                        try {
                            const response = await Axios(cfg);
                            if (response.status === 200) {
                                const objectUrl = URL.createObjectURL(response.data);
                                cacheRef.current[cfg.url!] = objectUrl;
                            }
                        } catch (error) {
                            console.error(`Failed to load image at ${cfg.url}:`, error);
                        }
                    })
                );

                setResult({
                    imageUrls: urls.map((url) => cacheRef.current[url]).filter(Boolean),
                    loading: false,
                });
            } else {
                setResult({ imageUrls: [], loading: false });
            }
        };

        loadImages();
    }, [givenUrl]);

    return result;
};



export const useGlobalEvent = (
    event: string,
    handler: EventListener,
    options?: AddEventListenerOptions
) => {
    React.useEffect(() => {
        document.addEventListener(event, handler, options);

        return () => {
            document.removeEventListener(event, handler, options);
        };
    }, [event, handler, options]);
};

export const useContainerWidth = (): [React.RefObject<HTMLDivElement>, number] => {
    const containerRef = React.useRef<HTMLDivElement>(null);
    const [width, setWidth] = React.useState<number>(0);

    React.useEffect(() => {
        const observer = new ResizeObserver(entries => {
            for (let entry of entries) {
                setWidth(entry.contentRect.width);
            }
        });

        const currentRef = containerRef.current; // Speichere den aktuellen Wert

        if (currentRef) {
            observer.observe(currentRef);
        }

        return () => {
            if (currentRef) {
                observer.unobserve(currentRef);  // Cleanup mit der gesicherten Referenz
            }
        };
    }, []);

    return [containerRef, width];
};