import { fadeColor, getColor, lightenColor, styled } from 'imaginarity-react-ui';
import * as React from 'react';


export const Header = styled.div`
        width: 100%;
        line-height:40px;
        font-weight:bolder;
        font-size:1.1rem;
`;
export const TabContainer = styled.div`
        margin-top:20px;
        width: 100%;
        height: 42px;
        border-bottom: 2px solid ${props => getColor(props, props.theme.colors.veryLightGrey)};
`;
export const InputContainer = styled.div<{ isMobile: boolean }>`
        margin-top:15px;
        width: 100%;
        padding: ${props => props.isMobile ? "0 5px" : 0};
`;
export const Tab = styled.div<{ active: boolean, disabled: boolean }>`
        line-height:40px;
        font-weight: ${props => props.active ? "bolder" : "normal"};
        background: ${props => props.active ? getColor(props, props.theme.colors.veryLightGrey) : "transparent"};
        border-bottom: 2px solid ${props => props.active ? getColor(props, props.theme.colors.accent) : "transparent"};
        display: inline-block;
        text-align: center;
        width: 100px;
        
        cursor: ${props => props.disabled ? "not-allowed" : props.active ? "default" : "pointer"};
        color: ${props => props.disabled ? lightenColor(props, getColor(props, props.theme.colors.veryLightGrey), 90) : getColor(props, props.theme.colors.mainForeground)};
        transition: 0.2s all ease-out;
        &:hover{
            border-bottom: 2px solid ${props => props.disabled ? lightenColor(props, getColor(props, props.theme.colors.veryLightGrey), 90) : getColor(props, props.theme.colors.accent)};
            font-weight: bolder;
        }
`;

export const CSTitleIcon = styled.div<{ invisible: boolean }>`
        width:100%;
        height:40px;
        line-height:40px;
        text-align: center;
        svg{
                stroke: ${props => props.invisible ? getColor(props, props.theme.colors.lightGrey) : undefined};
        }
`;
export const StickyContents = styled.div<{ sticky: boolean }>`
        position: ${p => p.sticky ? "sticky" : "static"};
        top: 0px;
        z-index: 500;
        width: 100%;
`;
export const StickyContentKind = styled.div<{ sticky: boolean }>`
        position: ${p => p.sticky ? "sticky" : "static"};
        top: 0px;
        z-index: 500;
        background: ${p => getColor(p, "@mainBackground")};
        padding-top: 10px;
        padding-bottom: 0px;
        width: 100%;
`;
export const AGCTitle = styled.div<{ missingInput?: boolean }>`
        width:100%;
        line-height:40px;
        height:40px;
        float: left;
        font-weight: bolder;
        font-size: 1em;
        margin-bottom:5px;
        color: ${props => props.missingInput ? getColor(props, props.theme.colors.accent) : getColor(props, props.theme.colors.mainForeground)};
`;
export const ExpertSwitchContainer = styled.div<{ active: boolean }>`
        display: grid;
        grid-template-columns: max-content max-content;
        column-gap: 10px;
        transition: 0.2s all ease-out;
        cursor: pointer;
        background: ${p => p.active ? getColor(p, "@accentLightBlue") : getColor(p, "@veryLightGrey")};
        color: ${p => p.active ? getColor(p, "@veryLightGrey") : getColor(p, "@darkGrey")};
        padding: 0 15px;
        line-height: 40px;
        &:hover{
                opacity: 1;                
                color: ${p => p.active ? getColor(p, "@mainBackground") : getColor(p, "@accentBlack")};
                background: ${p => p.active ? lightenColor(p, "@accentLightBlue", 90) : lightenColor(p, "@veryLightGrey", 95)};
        }
`;
export const ExpertSwitch = styled.div`
        min-width: 90px;
`;
export const AGCTitleRadio = styled.div<{ selected: boolean }>`
        float: left;
        margin-right: 15px;
        margin-top: 0;
        pointer-events: ${p => p.selected ? "none" : "all"};
        *{
                font-weight: ${p => p.selected ? "bolder" : "normal"};
        }
        svg{
                margin-top: 10px;
        }
`;
export const AGCTitleList = styled.div<{ height: number, missingInput?: boolean }>`
        width:100%;
        line-height: ${p => p.height}px;
        height: ${p => p.height}px;
        float: left;
        font-weight: bolder;
        font-size: 1em;
        color: ${props => props.missingInput ? getColor(props, props.theme.colors.accent) : getColor(props, props.theme.colors.mainForeground)};
        border-bottom: 1px solid ${props => getColor(props, "@middleLightGrey")};
`;
export const AGCInputMissingElement = styled.span`
        font-weight: bolder;
        margin-right:10px;
`;
export const AGCInput = styled.div`
        width:100%;
        height:40px;
        line-height:40px;
        margin-bottom:5px;
`;
export const FixedInfo = styled.div`
        width:320px;
        position: fixed;
        bottom:50px;
        left: 50%;
        transform: translateX(-50%);
        background: ${props => fadeColor(props, getColor(props, props.theme.colors.accent), 10)};
        padding:10px;
        text-align: center;
        color: ${props => getColor(props, props.theme.colors.accent)};
        opacity: 0.3;
        transition: 0.2s all ease-out;
        &:hover{
                opacity: 1;
        }
`;
export const AGCInputMissing = styled.div`
        width:100%;
        color: ${props => getColor(props, props.theme.colors.accent)};
        line-height:40px;
        margin-bottom:5px;
`;
export const CSTitle = styled.div`
        width: 100%;
        height: 40px;
        line-height: 40px;
        padding: 0 10px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
`;
export const CSTitleInfo = styled.div<{ color: string }>`
        position: absolute;
        text-align: center;
        width: 18px;
        height: 18px;
        line-height: 16px;
        margin-top: 2px;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        /* border: 1px solid ${p => getColor(p, p.color)}; */
        color: ${p => getColor(p, p.color)};
        /* border-radius: 50%; */
        font-size: 0%.9;
`;
export const CSTitleInfoContainer = styled.div`
        position: relative;
        width: 40px;
        height: 40px;
        margin-top: -1px;
        &:hover{
        background: rgba(0,0,0,0.15);
        }
`;
export const CSTitleButton = styled.div`
        width:100%;
        height:40px;
        line-height: 40px;
        button>div{ margin: 0; }
`;

export const CurSeqContainerStart = styled.div`
        border-bottom: 1px solid ${props => getColor(props, props.theme.colors.lightGrey)};
`;
export const CurSeqContainer = styled.div<{ color: string, isReference?: boolean }>`
        border-top: 1px solid  ${p => fadeColor(p, "@darkGrey", 15)};
        background-color: ${p => p.isReference ? fadeColor(p, p.color, 3) : "transparent"};
        height: 40px;
        margin-left: -10px;
        color: ${p => p.isReference ? lightenColor(p, p.color, 50) : getColor(p, "@darkGrey")};
        transition: 0.2s all ease-in;
        .itemIcon svg{
                stroke: ${p => p.isReference ? getColor(p, p.color) : "inherit"};
        }
`;
export const CurSeqDetailContainerOuter = styled.div`
        height:40px;
        padding: 0px;
        margin-bottom:5px;
        position: relative;
        transition: 0.2s all ease-in;
        button>div{margin: 0;}
`;
export const CurSeqDetailContainer = styled.div<{ invisible: boolean, color: string, isIn?: boolean }>`
        border: 1px solid ${p => getColor(p, p.color)};
        padding: 0px;
        width: calc(100% - 40px);
        height:40px;
        color: ${props => props.invisible ? getColor(props, props.theme.colors.lightGrey) : getColor(props, props.theme.colors.mainForeground)};
        transition: 0.2s all ease-in;
        background: ${p => fadeColor(p, p.color, p.isIn ? 7 : 2)};
        button>div{margin: 0;}
`;
export const CurSeqDetailContainerOLD = styled.div<{ invisible: boolean, color: string, isIn?: boolean }>`
        border: 1px solid ${p => getColor(p, p.color)};
        padding:0px 0px 0px 0px;
        height:40px;
        margin-bottom:5px;
        color: ${props => props.invisible ? getColor(props, props.theme.colors.lightGrey) : getColor(props, props.theme.colors.mainForeground)};
        position: relative;
        transition: 0.2s all ease-in;
        background: ${p => fadeColor(p, p.color, p.isIn ? 7 : 2)};
        button>div{margin: 0;}
`;

export const NodeConnectorHleft = styled.div<{ color: string }>`
    position: absolute;
    right: 0px;
    top: 50%;
    //transform: translate(100%);
    width: 41px;
    height: 1px;
    background: ${p => getColor(p, p.color)};
`
export const CurSeqContainerChevron = styled.div`
        width: 100%;
        text-align: center;
        margin: -3px 0;
`;

export const AGCInputTransition = styled.div`
        width:calc(100% - 35px);
        height:40px;
        line-height:40px;
        margin-bottom:5px;
        float: right;
`;
export const AGCInputTransitionExplanation = styled.div`
        width:40px;
        height:40px;
        margin-bottom:5px;
        float: left;
        position: relative;
        margin-left:-7px;
`;
export const InfoText = styled.div`
        position: absolute;
        width: 150px;
        padding:10px;
        font-size:0.8rem;
        background: #FFF;
        left: -154px;
        top:3px;
        border:1px solid ${props => getColor(props, props.theme.colors.lightGrey)};
        background:${props => getColor(props, props.theme.colors.veryLightGrey)};
        box-shadow: 0 6px 8px -6px ${props => getColor(props, props.theme.colors.mainForeground)};
`;

export const AGCInputRadio = styled.div`
        width:100%;
`;
export const AGCInputTextArea = styled.div`
        width:100%;
        height: fit-content;
        margin-bottom:5px;
        background: ${props => getColor(props, props.theme.colors.veryLightGrey)};
`;
export const AGCInputDate = styled.div`
        //width: calc(100% + 1.15rem);
        //height:40px;
        //margin-left:-0.25rem;
        //margin-bottom: 5px;
`;
export const PreviewTitle = styled.div`
        height:40px;
        line-height:40px;
        float: right;
        color: ${props => getColor(props, props.theme.colors.lightGrey)};
`;

export const AGCInputButton = styled.label`
    border: 1px solid ${props => getColor(props, props.theme.colors.lightGrey)};
    display: inline-block;
    padding: 0px 12px;
    line-height: 40px;
    font-family: ${props => props.theme.fontFamily};
    font-size:1em;
    background: ${props => getColor(props, props.theme.colors.mainBackground)};
    cursor: pointer;
    color: ${props => getColor(props, props.theme.colors.mainForeground)};
    height:40px;
    transition: 0.1s all ease-in;
    svg{
        stroke: ${props => getColor(props, props.theme.colors.mainForeground)};
    }
    &:hover{
        border: 1px solid ${props => getColor(props, props.theme.colors.mainForeground)};
        background: ${props => getColor(props, props.theme.colors.veryLightGrey)};
    }
`;
export const AGCInputSearch = styled.div`
        width:100%;
        margin-bottom: -1em;
`;
export const AGCInputElementsContainer = styled.div<{ missingInput?: boolean }>`
        width:100%;
        margin-bottom:5px;
        border: 1px solid ${props => props.missingInput ? getColor(props, props.theme.colors.accent) : getColor(props, props.theme.colors.lightGrey)};
        background: ${props => props.missingInput ? fadeColor(props, getColor(props, props.theme.colors.accent), 10) : undefined};
`;
export const AGCInputElementsContainerPl = styled.div<{ maximized?: boolean }>`
        width:100%;
        margin-bottom:5px;
        border: ${props => props.maximized ? "1px solid " + getColor(props, props.theme.colors.lightGrey) : "none"};
        height: ${props => props.maximized ? "fit-content" : "0px"};
        transition: height 0.2s ease-out;
`;
export const AGCIEicon = styled.div<{ isSelectedElement: boolean }>`
        svg{
          stroke: ${props => props.isSelectedElement ? getColor(props, props.theme.colors.accent) : getColor(props, props.theme.colors.mainForeground)};
        }
`;
export const AGCIEtitle = styled.div<{ isSelectedElement: boolean, isUsed: boolean }>`
        color: ${props => props.isUsed ? getColor(props, props.theme.colors.accent) : props.isSelectedElement ? getColor(props, props.theme.colors.accent) : getColor(props, props.theme.colors.mainForeground)};
        font-weight: ${props => props.isSelectedElement ? "bolder" : "normal"};
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        font-weight: ${props => props.isUsed ? "bolder" : "normal"};
`;
export const AGCInputElements = styled.div<{ selected?: boolean }>`
        width:100%;
        padding: 0px 5px 0px 10px;
        line-height:38px;
        display: grid;
        display: -ms-grid;
        grid-template-columns: 25px auto 36px;
        -ms-grid-columns: 25px auto 36px;
        grid-template-rows:38px;
        -ms-grid-rows: 38px;
        align-items: center;
        color: ${props => props.selected ? getColor(props, props.theme.colors.accent) : undefined};
        &:hover{
            background: ${props => getColor(props, props.theme.colors.veryLightGrey)};
        }
        svg{
                stroke: ${props => props.selected ? getColor(props, props.theme.colors.accent) : undefined};
        }
`;
export const ImplementedElementsHeadline = styled.div`
        width:100%;
        margin-bottom:5px;
        line-height:40px;
        font-weight: bolder;
        font-size:1rem;
`;
export const ImplementedElementsHeadlineOnlyAAO = styled.div`
        width:100%;
        margin-bottom:5px;
        line-height:40px;
        font-weight: bolder;
        font-size: 1.1em;
        button>div{margin: 0;}
`;

export const SequenceContainerOuter = styled.div<{ color: string }>`
        padding-right: 10px;
        padding-left: 40px;
        z-index: 3;
`;
export const SequenceAAOContainerOuter = styled.div`
        padding-right: 10px;
        height: 100%;
`;

export const ImplementedElementsContainer = styled.div<{ typeIsFileEntry: boolean, sortByType: boolean, showAsList: boolean }>`
        width: 100%;
        padding: 0px;
        height: ${p => p.showAsList ? 26 : 40}px;
        line-height: ${p => p.showAsList ? 26 : 40}px;
        display: grid;
        grid-template-columns: ${props => props.sortByType ? props.typeIsFileEntry ? "45px 80px 1fr max-content" : "40px 0px 1fr max-content" : "40px 130px 1fr max-content"};
        //border: 1px solid ${props => getColor(props, "@middleLightGrey")};
        border: ${p => p.showAsList ? "none" : "1px solid " + getColor(p, p.theme.colors.veryLightGrey)};
        border-top: none;
        margin-top: ${p => p.showAsList ? -3 : 0}px;
        &:hover{
            background: ${props => getColor(props, props.theme.colors.veryLightGrey)};
        }
`;

export const IECIcon = styled.div`
    grid-column-start: 1;
    grid-row-start: 1;
    text-align: center;
`;
export const IECType = styled.div`
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    padding-right:10px;
    grid-column-start: 2;
    grid-row-start: 1;
`;
export const IECTitle = styled.div<{ notRemoveable: boolean, showAsList: boolean }>`
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    font-weight: ${props => props.notRemoveable ? "normal" : "bolder"};
    padding-right: 10px;
    line-height: ${p => p.showAsList ? 26 : 38}px;
    grid-column-start: 3;
    grid-row-start: 1;
`;
export const IECDescription = styled.div`
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    padding-right:10px;
    grid-column-start: 4;
    grid-row-start: 4;
`;
export const IECButtons = styled.div`
    grid-column-start: 5;
    grid-row-start: 1;
    button>div{margin: 0;}
`;
export const GraphicalSequenceContainer = styled.div`
    width: 100%;
    padding: 40px 20px 40px 0;
    background: ${props => getColor(props, props.theme.colors.veryLightGrey)};
    overflow: auto;
    position: relative;
`;
export const GraphicalSequenceContainerButton = styled.div`
    position: absolute;
    right:0;
    top:0;
`;
export const GraphicalSequenceContainerDummyImage = styled.div<{ maximize: boolean }>`
    width: ${props => props.maximize ? "100%" : "270%"};
    height: calc(100vh - 400px);
    background: url("https://cdn.imaginarity.com/public/PlaylistDummy.png"); 
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;
`;
export const GroupSelectContainer = styled.div`
    line-height: 28px;
`;
export const Label = styled.div`
    font-weight: bolder;
    line-height:1.5rem;
    font-size:0.9rem;
    line-height: 40px;
`;
export const BtnCon = styled.div`
    border: 1px solid ${p => getColor(p, "@middleLightGrey")};
`;
export const FooterContainer = styled.div`
    margin-top: 40px;
    border-top: 1px solid ${p => getColor(p, "@middleLightGrey")};
    padding-top: 20px;
    margin-left: 40px;
`;

export const SMIEContainer = styled.div`
        line-height: 25px;
        display: grid;
        grid-template-columns: 1fr max-content max-content;
        cursor: pointer;
        &:hover{
                background: ${p => getColor(p, "@veryLightGrey")};
        }
`;
export const SMIECTitle = styled.div`
        text-overflow: ellipsis;
        overflow: hidden;
        padding-right: 5px;
        white-space: nowrap;
`;

export const FileEntryHint = styled.span`
        padding-right: 5px;
        font-size: 1em;
        float: left;
        //color: ${p => getColor(p, "@lightGrey")};
`;

export default class PlaylistEditClasses extends React.Component<any> { }
