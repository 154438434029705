import { ControllerHelper, EventController, EventUploadDto, PlaylistEdgeTransitionFunction, PlaylistItemStateDownloadDto, WikiArticleDownloadDto } from 'collaboration-service';
import DateTimeFns from 'components/DateTime/DateTimeFns';
import SafeHTML from 'components/SafeHTML/SafeHTML';
import { Button, Dimmer, Icon } from 'imaginarity-react-ui';
import * as React from 'react';
import { ApplicationState, useAppSelector } from "services/ApplicationState/ApplicationState";
import { DueInfo, shallowCompare } from 'services/Helpers';
import { getTranslated } from 'services/Helpers/TranslationHelpers';
import { useImgI18N } from 'services/ImgI18N';
import { PlaylistDetailSC as T } from "../../PlaylistDetailSC";
import { getMediaLink } from 'services/Helpers/MediaHelpers';
import { bannerTop } from 'services/Config';
import PreviewWikiArticle from './Previews/PreviewWikiArticle';
import MarkAsReadButton from './MarkAsReadButton';
import { LoadPlaylistStates } from './PlaylistTubePodcastDimmer';

const mapper = (s: ApplicationState) => ({
    contentLanguage: s.contentLanguage,
    currentGroup: s.currentGroup,
    currentArticle: s.wikiState.currentArticle,
});

interface PlaylistWikiDimmerProps extends LoadPlaylistStates {
    id: string;
    playlistStateId?: string;
    item: WikiArticleDownloadDto;
    link: string;
    result: DueInfo;
    playlistId: string;
    state: PlaylistItemStateDownloadDto;
    isOptional: boolean;
    transition: PlaylistEdgeTransitionFunction | undefined;
    elementIsReference: boolean;
    nodeIsDone: boolean;
    warning: boolean;
    closeDimmer: () => void;
}

const PlaylistWikiDimmer = (p: PlaylistWikiDimmerProps) => {
    const { contentLanguage, currentGroup, currentArticle } = useAppSelector(mapper, shallowCompare);
    const { id, item, link, result, playlistId, state, isOptional, transition, elementIsReference, nodeIsDone, warning, closeDimmer, loadPlaylistStates, playlistStateId } = p;
    const { t } = useImgI18N('playlist');

    const clickOnItem = React.useMemo(() => () => {
        if (currentGroup && playlistId) {
            const ev: EventUploadDto = {
                eventType: "PostViewed",
                group_id: currentGroup.id,
                payload: {
                    "PostId": item.id,
                    "From": "playlist",
                    "PlaylistId": playlistId,
                }
            };
            ControllerHelper.singleCall({ ev }, EventController.PostEvent);
        }
    }, [currentGroup, playlistId, item.id]);
    const bannerAlt = bannerTop.wikiArticleAlternative;
    const banner = (currentArticle ? getMediaLink(currentArticle, a => a.media) : undefined) ?? bannerAlt;

    return (
        <Dimmer active >
            <T.DimmerContainerWiki>

                <T.StickyCloseButton>
                    <T.DimmerInfoContainerSticky onClick={closeDimmer}>
                        <Icon name="times" size={27} marginTop={3} />
                    </T.DimmerInfoContainerSticky>
                </T.StickyCloseButton>

                <T.DimmerImageContainer imageUrl={banner} style={{ zIndex: 1 }} />


                <T.DimmerInfoContainerWiki>

                    <T.DimmerInfoContainerTitle>
                        <SafeHTML html={getTranslated(item.headlines, contentLanguage)?.text ?? ""} allowedTags={[]} allowedAttributes={{}} />
                    </T.DimmerInfoContainerTitle>
                    <T.DimmerInfoContainerButton>
                        <Button
                            icon="link external"
                            content={t("open in ppe")}
                            bordered
                            kind="secondary"
                            floated="right"
                            asHtmlLinkToRoute={link}
                            asHtmlLinkToRouteTarget='_blank'
                            onClick={clickOnItem}
                            tooltip={{ tooltipText: t("link opens in new browser tab"), zIndex: 2147483638 }}
                        />
                        <MarkAsReadButton
                            playlistId={playlistId}
                            playlistItemState={state}
                            item={item}
                            lastAction={loadPlaylistStates}
                        />
                    </T.DimmerInfoContainerButton>
                    <T.DimmerInfoContainerDate>
                        {result.dueToDate &&
                            <div style={{ float: "left" }}>
                                <DateTimeFns
                                    date={result.dueToDate}
                                    noTime
                                    hideIcons
                                    textColor="grey"
                                />
                            </div>
                        }
                        {isOptional ?
                            <T.ColoredSpan color={state.state === "Finished" ? "@accentGreen" : "inherit"}>
                                {state.state === "Finished" ? t("done") : t("optional")}
                            </T.ColoredSpan>
                            :
                            (transition === "ReferenceDone" || transition === "ReferenceDoneWithValue") ?
                                elementIsReference ?
                                    <T.ColoredSpan color={(result.text === "100%" || nodeIsDone) ? "@accentGreen" : warning ? "@warning" : result.color}>
                                        {nodeIsDone ? t("done") : warning ? t("warning") : result.text}
                                    </T.ColoredSpan>
                                    :
                                    <T.ColoredSpan color={result.text === "100%" ? "@accentGreen" : "@middleLightGrey"}>
                                        {result.text === "100%" ? t("done") : t("optional")}
                                    </T.ColoredSpan>
                                :
                                <T.ColoredSpan color={result.text === "100%" ? "@accentGreen" : warning ? "@warning" : result.color}>
                                    {result.text === "100%" ? t("done") : t(result.text)}
                                </T.ColoredSpan>
                        }
                    </T.DimmerInfoContainerDate>

                </T.DimmerInfoContainerWiki>
                <T.DimmerInfoContainerDescription style={{ wordBreak: "break-word" }}>
                    <PreviewWikiArticle directId={id} context={playlistStateId} />
                </T.DimmerInfoContainerDescription>
                {/* <T.DimmerInfoContainerClose onClick={closeDimmer} >
                    <Icon name="times" size={27} marginTop={3} />
                </T.DimmerInfoContainerClose> */}
            </T.DimmerContainerWiki>
        </Dimmer>
    );
}
export default PlaylistWikiDimmer;