import { ControllerHelper, EventController, EventUploadDto, FileEntryDownloadDto, PlaylistEdgeTransitionFunction, PlaylistItemStateDownloadDto } from 'collaboration-service';
import DateTimeFns from 'components/DateTime/DateTimeFns';
import SafeHTML from 'components/SafeHTML/SafeHTML';
import { Button, Dimmer, getColor, getLinkByName, Icon } from 'imaginarity-react-ui';
import * as React from 'react';
import { ApplicationState, useAppSelector } from "services/ApplicationState/ApplicationState";
import { DueInfo, shallowCompare } from 'services/Helpers';
import { sanitizedNothingAllowedAttributes } from 'services/Helpers/SanitizedHelper';
import { getCMSContent, getTranslated } from 'services/Helpers/TranslationHelpers';
import { useImgI18N } from 'services/ImgI18N';
import { ThemeContext } from 'styled-components';
import { PlaylistDetailSC as T } from "../../PlaylistDetailSC";
import { LoadPlaylistStates } from './PlaylistTubePodcastDimmer';

const mapper = (s: ApplicationState) => ({
    contentLanguage: s.contentLanguage,
});

interface PlaylistFileEntryImageDimmerProps extends LoadPlaylistStates {
    playlistStateId?: string;
    id: string;
    item: FileEntryDownloadDto;
    result: DueInfo;
    playlistId: string;
    state: PlaylistItemStateDownloadDto;
    isOptional: boolean;
    transition: PlaylistEdgeTransitionFunction | undefined;
    elementIsReference: boolean;
    nodeIsDone: boolean;
    warning: boolean;
    closeDimmer: () => void;
    isPodcast?: boolean;
}

const PlaylistFileEntryDownloadDimmer = (p: PlaylistFileEntryImageDimmerProps) => {
    const { contentLanguage } = useAppSelector(mapper, shallowCompare);
    const { item, result, playlistId, state, isOptional, transition, elementIsReference, nodeIsDone, warning, closeDimmer, loadPlaylistStates } = p;
    const { t, currentLanguage } = useImgI18N('playlist');
    const language = contentLanguage ?? currentLanguage;
    const translatedContent = React.useMemo(() => {
        const translatedContent = getCMSContent({ fileEntry: item, filePosts: {}, language });
        return translatedContent;
    }, [item, language])
    const [downloaded, setDownloaded] = React.useState<boolean>(false);

    const download = React.useMemo(() => () => {
        if (playlistId) {
            const ev: EventUploadDto = {
                eventType: "PostViewed",
                group_id: item.group_id,
                payload: {
                    "PostId": item.id,
                    "From": "playlist",
                    "PlaylistId": playlistId,
                }
            };
            ControllerHelper.singleCall({ ev }, EventController.PostEvent);
            const ev1: EventUploadDto = {
                eventType: "Finished",
                group_id: item.group_id,
                payload: {
                    "PostId": item.id,
                    "From": "playlist",
                    "PlaylistId": playlistId,
                }
            };
            ControllerHelper.singleCall({ ev: ev1 }, EventController.PostEvent);
        }
        const dwn = getLinkByName(translatedContent?.media, "download")?.uri ?? "";
        const ele = document.createElement("a");
        ele.setAttribute("download", "download");
        ele.setAttribute("href", dwn ?? "");
        ele.style.display = "none";
        ele.click();
        setDownloaded(true);
        setTimeout(() => {
            if (loadPlaylistStates) {
                loadPlaylistStates();
            }
        }, 5000);
    }, [translatedContent, item.id, playlistId, item.group_id, loadPlaylistStates]);

    const theme = React.useContext(ThemeContext);
    const content = getCMSContent({ fileEntry: item, filePosts: {}, language })

    const title = getTranslated(item?.headlines, contentLanguage)?.text ?? content?.media?.fileName;


    return (
        <Dimmer active >
            <T.DimmerContainer>
                <div style={{ padding: "30px 40px", fontSize: "1rem", background: getColor({ theme }, "@veryLightGrey"), fontWeight: "bolder", color: getColor({ theme }, "@accentRed") }}>
                    {downloaded ? t('states will be actualized, do not wait for it') : t("this content can only be downloaded.")}
                </div>
                <T.DimmerInfoContainer>

                    <T.DimmerInfoContainerTitle>
                        <SafeHTML html={title} allowedTags={[]} allowedAttributes={{}} />
                    </T.DimmerInfoContainerTitle>
                    <T.DimmerInfoContainerButton>
                        <Button
                            icon="link external"
                            content={state.state === "Finished" ? t("start download again") : t("start download")}
                            bordered
                            kind="secondary"
                            floated="right"
                            onClick={download}
                        />
                    </T.DimmerInfoContainerButton>
                    <T.DimmerInfoContainerDate>
                        {result.dueToDate &&
                            <div style={{ float: "left" }}>
                                <DateTimeFns
                                    date={result.dueToDate}
                                    noTime
                                    hideIcons
                                    textColor="grey"
                                />
                            </div>
                        }
                        {isOptional ?
                            <T.ColoredSpan color={state.state === "Finished" ? "@accentGreen" : "inherit"}>
                                {state.state === "Finished" ? t("done") : t("optional")}
                            </T.ColoredSpan>
                            :
                            (transition === "ReferenceDone" || transition === "ReferenceDoneWithValue") ?
                                elementIsReference ?
                                    <T.ColoredSpan color={(result.text === "100%" || nodeIsDone) ? "@accentGreen" : warning ? "@warning" : result.color}>
                                        {nodeIsDone ? t("done") : warning ? t("warning") : result.text}
                                    </T.ColoredSpan>
                                    :
                                    <T.ColoredSpan color={result.text === "100%" ? "@accentGreen" : "@middleLightGrey"}>
                                        {result.text === "100%" ? t("done") : t("optional")}
                                    </T.ColoredSpan>
                                :
                                <T.ColoredSpan color={result.text === "100%" ? "@accentGreen" : warning ? "@warning" : result.color}>
                                    {result.text === "100%" ? t("done") : t(result.text)}
                                </T.ColoredSpan>
                        }
                    </T.DimmerInfoContainerDate>
                </T.DimmerInfoContainer>

                <T.DimmerInfoContainerDescription>
                    <SafeHTML
                        html={getTranslated(item.descriptions, contentLanguage).text ?? ""}
                        allowedTags={["strong", "em", "b", "i", "u", "p"]}
                        allowedAttributes={sanitizedNothingAllowedAttributes}
                    />
                </T.DimmerInfoContainerDescription>
                <T.DimmerInfoContainerClose onClick={closeDimmer} >
                    <Icon name="times" size={27} marginTop={3} />
                </T.DimmerInfoContainerClose>
            </T.DimmerContainer>
        </Dimmer>
    );
}
export default PlaylistFileEntryDownloadDimmer;