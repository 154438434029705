import { PALMSUserTraining } from "collaboration-service";
import { SlideBreakpoints, myHomeLearningPathContentBreakpoints, slideBreakpoints } from "./MyHomeSettings";

export function isDone(lp: PALMSUserTraining) {
    return lp.deadline !== undefined && ((lp.status ?? "").startsWith("Completed")||(lp.status ??"").startsWith("Shared"));
};

export function isOverdue(lp: PALMSUserTraining) {
    return !lp.status.startsWith("Completed") && new Date(lp.deadline) < new Date();
};

export function isOnTrack(lp: PALMSUserTraining) {
    return !lp.deadline || (new Date(lp.deadline) > new Date() && !lp.status.startsWith("Completed"));
};

export function isStarted(lp: PALMSUserTraining, dueDays: number) {
    const s = (lp.status ?? "");
    return (
        lp.status !== undefined &&
        s.startsWith("In Process") &&
        dueDays >= 0
    );
};

export function getSlideProperties(width: number): SlideBreakpoints {
    const breakpoint = slideBreakpoints.find(bp => width <= bp.breakpoint) || slideBreakpoints[slideBreakpoints.length - 1];
    return breakpoint;
}

export function getShowColumns(width: number) {
    const flags = {
        showCategory: false, //width >= myHomeLearningPathContentBreakpoints[2].width,
        showDate: width >= myHomeLearningPathContentBreakpoints[1].width,
        showProgress: width >= myHomeLearningPathContentBreakpoints[0].width,
    };
    return flags;
};
