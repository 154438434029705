import { BestPracticePostDownloadDto, CategoryDownloadDto, ContentPostDownloadDto, FileEntryDownloadDto, PlaylistContentDownloadDto, PlaylistEdgeTransitionFunction, PlaylistItemStateDownloadDto, PodcastPostDownloadDto, PorscheMomentPostDownloadDto, PostDownloadDto, QuizDownloadDto, TubePostDownloadDto, WikiArticleDownloadDto } from 'collaboration-service';
import { ImgIcons } from 'imaginarity-react-ui';
import * as React from 'react';
import { useAppSelector } from 'services/ApplicationState/ApplicationState';
import { calculateDueInfo, DueToType } from 'services/Helpers';
import BestPracticePlaylistPostNodeItem from './PlaylistItems/BestPracticePlaylistPostNodeItem';
import ContentPostPlaylistNodeItem from './PlaylistItems/ContentPostPlaylistNodeItem';
import FileEntryPlaylistNodeItem from './PlaylistItems/FileEntryPlaylistNodeItem';
import PALMSPlaylistNodeItem from './PlaylistItems/PALMSPlaylistNodeItem';
import PodcastPostPlaylistNodeItem from './PlaylistItems/PodcastPostPlaylistNodeItem';
import PorscheMomentPostPlaylistNodeItem from './PlaylistItems/PorscheMomentPostPlaylistNodeItem';
import QuizPlaylistNodeItem from './PlaylistItems/QuizPlaylistNodeItem';
import TubePostPlaylistNodeItem from './PlaylistItems/TubePostPlaylistNodeItem';
import WikiArticlePlaylistNodeItem from './PlaylistItems/WikiArticlePlaylistNodeItem';


export interface PlaylistItemProps {
    item: PostDownloadDto;
    orderNumber: number;
    state: PlaylistItemStateDownloadDto;
    isMobile: boolean;
    categories?: CategoryDownloadDto[];
    paddedRight?: boolean;
    playlistId?: string;
    playlistContent?: PlaylistContentDownloadDto;
    playlistStateId?: string;
    accent?: string;
}
export interface SePlaylistItemState {
    showFullDescription: boolean;
}
export interface PlaylistDetailNodeItemProps<T extends PostDownloadDto> {
    item: T;
    orderNumber: number;
    state: PlaylistItemStateDownloadDto;
    isMobile: boolean;
    categories?: CategoryDownloadDto[];
    paddedRight?: boolean;
    playlistId?: string;
    accentColor: string;
    playlistContent?: PlaylistContentDownloadDto;
    playlistStateId?: string;
    accent?: string;
    isOptional?: boolean;
    dueToType: DueToType;
    assignmentDate?: Date;
    absoluteDueDate?: Date;
    dueDateRelativeInHours?: number;
    nodeReference?: string;
    transition?: PlaylistEdgeTransitionFunction;
    warning?: boolean;
    nodeIsDone?: boolean;
    loadPlaylistStates?: () => Promise<void>;
    preview?: boolean;

    //node: PlayListNodeDownloadDto;

}
type UseHidePlaylistNodeItemProps = {
    state: PlaylistDetailNodeItemProps<PostDownloadDto>["state"];
    dueToType: PlaylistDetailNodeItemProps<PostDownloadDto>["dueToType"];
    assignmentDate?: PlaylistDetailNodeItemProps<PostDownloadDto>["assignmentDate"];
    absoluteDueDate?: PlaylistDetailNodeItemProps<PostDownloadDto>["absoluteDueDate"];
    dueDateRelativeInHours?: PlaylistDetailNodeItemProps<PostDownloadDto>["dueDateRelativeInHours"];
    isOptional?: PlaylistDetailNodeItemProps<PostDownloadDto>["isOptional"];
    warning?: PlaylistDetailNodeItemProps<PostDownloadDto>["warning"];
}

export const useHidePlaylistNodeItem = (p: UseHidePlaylistNodeItemProps) => {
    const { state, assignmentDate, dueToType, absoluteDueDate, dueDateRelativeInHours, isOptional, warning } = p;
    const result = calculateDueInfo(dueToType, state.state, assignmentDate, absoluteDueDate, dueDateRelativeInHours, isOptional, warning);
    const filter = useAppSelector(s => s.playlistsState.filter);
    const hideFinished = filter ? !filter['finished'] : false;
    const hideOnTrack = filter ? !filter['ontrack'] : false;
    const hideOvedue = filter ? !filter['overdue'] : false;
    const hideWarning = filter ? !filter['warning'] : false;
    const hideOptional = filter ? !filter['optional'] : false;
    if (hideFinished && result.text === "100%")
        return { hide: true, ...result };
    if (hideOnTrack && result.text === "on Track")
        return { hide: true, ...result };
    if (hideOvedue && result.text === "overdue")
        return { hide: true, ...result };
    if (hideWarning && result.text === "warning")
        return { hide: true, ...result };
    if (hideOptional && result.text === "optional")
        return { hide: true, ...result };
    return { hide: false, ...result };
}

export type PlaylistItemInfo = {
    image: string;

    title: string;
    description: string;

    duration?: string;

    icon: ImgIcons;
    typeName: string;

    statusText: string;
    statusIcon: ImgIcons;
    statusColor: string;
    adminLink?: string;

    modal: JSX.Element;
}


// const PlaylistDetailNodeItemInternal = (p: PlaylistDetailNodeItemProps<PostDownloadDto>) => {
//     const { item, nodeReference, node } = p;
//     const [showModal, setshowModal] = React.useState<boolean>(false);
//     const isMobile = useBelowBreakpointOrEqual("largeMobile");
//     const elementIsReference = nodeReference !== undefined ? item.id === nodeReference : false;
//     const {t} = useImgI18N("playlist");

//     const { toggleModal } = React.useMemo(() => {
//         return {
//             toggleModal: () => setshowModal(s => !s),
//         }
//     }, []);

//     const nodeInfo = React.useMemo<PlaylistItemInfo>(() => {
//         switch (item.type) {
//             default:
//                 return {
//                     image: "",

//                     title: "",
//                     description: "",
//                     icon: "empty",

//                     typeName: "",

//                     modal: <div />,
//                     statusColor: "@accentRed",
//                     statusIcon: "check circle",
//                     statusText: "done",

//                 }
//         }
//     }, [item]);

//     return (
//         <T.PLItemContainer isMobile={isMobile} isPreview={p.preview ?? false}>
//             <T.PLItemImage imageUrl={nodeInfo.image} isMobile={isMobile} highlighted={elementIsReference} color={warning ? "@warning" : "@accentRed"} onClick={toggleModal} />
//             <T.PLItemContainerContent isMobile={isMobile} onClick={toggleModal}>
//                 <T.PLItemContainerTitle isMobile={isMobile}>
//                     {elementIsReference &&
//                         <T.PLItemContainerTitleDot color={warning ? "@warning" : "@accentRed"} />
//                     }
//                     <SafeHTML html={nodeInfo.title} allowedTags={[]} allowedAttributes={{}} />
//                 </T.PLItemContainerTitle>
//                 {!isMobile &&
//                     <T.PLItemContainerDescription>
//                         {item.descriptions &&
//                             <SafeHTML
//                                 html={nodeInfo.description}
//                                 allowedTags={["strong", "span", "b", "i", "u"]}
//                                 allowedAttributes={sanitizedNothingAllowedAttributes}
//                             />
//                         }
//                     </T.PLItemContainerDescription>
//                 }
//                 <T.PLItemContainerBottomCat isMobile={isMobile}>
//                     {nodeInfo.adminLink ?
//                         <T.PLItemContainerBottomCatIconLink href={nodeInfo.adminLink} >
//                             <Tooltip tooltipText={t("open in cms")} noMargin notInline position="top">
//                                 <Icon name={nodeInfo.icon} style={{ float: "left", marginTop: -3, marginLeft: 2 }} size="15px" color="@accentRed" />
//                             </Tooltip>
//                         </T.PLItemContainerBottomCatIconLink>
//                         :
//                         <Icon name={nodeInfo.icon} marginTop={3} style={{ float: "left", marginLeft: -2, marginRight: 10 }} size="16px" />
//                     }
//                     {nodeInfo.typeName}
//                     {nodeInfo.duration &&
//                         <>
//                             <span style={{ padding: "0 10px" }}>&bull;</span>
//                             {nodeInfo.duration}
//                         </>
//                     }
//                 </T.PLItemContainerBottomCat>
//             </T.PLItemContainerContent>
//             {p.preview !== true &&
//                 <T.PLItemContainerRight isMobile={isMobile} onClick={toggleModal}>
//                     {isOptional ?
//                         <T.ColoredDiv color={state.state === "Finished" ? "@accentGreen" : "@lightGrey"}>
//                             <div style={{ float: "right" }}>{state.state === "Finished" ? t("done") : t("optional")} </div>
//                             <Icon name={state.state === "Finished" ? "check circle" : "empty"} style={{ float: "right", marginRight: 5 }} marginTop={4} color={"@accentGreen"} />
//                         </T.ColoredDiv>
//                         :
//                         (transition === "ReferenceDone" || transition === "ReferenceDoneWithValue") ?
//                             elementIsReference ?
//                                 <T.ColoredDiv color={(result.text === "100%" || nodeIsDone) ? "@accentGreen" : warning ? "@warning" : result.color}>
//                                     <div style={{ float: "right" }}>
//                                         {(result.text === "100%" || nodeIsDone) ? t("done") : warning ? t("warning") : result.text}
//                                     </div>
//                                     <Icon name={(result.text === "100%" || nodeIsDone) ? "check circle" : warning ? "exclamation triangle" : result.icon as ImgIcons} style={{ float: "right", marginRight: 5 }} marginTop={4} color={(result.text === "100%" || nodeIsDone) ? "@accentGreen" : warning ? "@warning" : result.color} />
//                                 </T.ColoredDiv>
//                                 :
//                                 <T.ColoredDiv color={(result.text === "100%" && nodeIsDone) ? "@accentGreen" : "@middleLightGrey"}>
//                                     <div style={{ float: "right" }}>{result.text === "100%" ? t("done") : t("optional")}</div>
//                                     <Icon name="empty" style={{ float: "right", marginRight: 5 }} marginTop={4} />
//                                 </T.ColoredDiv>
//                             :
//                             <T.ColoredDiv color={result.text === "100%" ? "@accentGreen" : warning ? "@warning" : result.color}>
//                                 <div style={{ float: "right" }}>
//                                     {result.text === "100%" ? t("done") : t(result.text)}
//                                 </div>
//                                 <Icon
//                                     name={result.icon as ImgIcons}
//                                     style={{ float: "right", marginRight: 5 }}
//                                     marginTop={4}
//                                     color={result.text === "100%" ? "@accentGreen" : warning ? "@warning" : result.color}
//                                 />
//                             </T.ColoredDiv>
//                     }
//                 </T.PLItemContainerRight>
//             }
//             {showModal &&
//                 <>
//                     {(type === "audio" || type === "video") &&
//                         <PlaylistFileEntryVideoPodcastDimmer
//                             isPodcast={type === "audio"}
//                             playlistStateId={p.playlistStateId}
//                             id={p.state.referenceId}
//                             item={item}
//                             result={result}
//                             playlistId={playlistId ?? ""}
//                             isOptional={isOptional ?? false}
//                             transition={transition}
//                             elementIsReference={elementIsReference}
//                             nodeIsDone={nodeIsDone ?? false}
//                             warning={warning ?? false}
//                             state={state}
//                             closeDimmer={togglePreview}
//                             loadPlaylistStates={loadPlaylistStates}
//                         />
//                     }
//                     {type === "pdf" &&
//                         <PlaylistFileEntryPDFDimmer
//                             playlistStateId={p.playlistStateId}
//                             id={p.state.referenceId}
//                             item={item}
//                             result={result}
//                             playlistId={playlistId ?? ""}
//                             isOptional={isOptional ?? false}
//                             transition={transition}
//                             elementIsReference={elementIsReference}
//                             nodeIsDone={nodeIsDone ?? false}
//                             warning={warning ?? false}
//                             state={state}
//                             closeDimmer={togglePreview}
//                             loadPlaylistStates={loadPlaylistStates}
//                         />

//                     }
//                     {type === "image" &&
//                         <PlaylistFileEntryImageDimmer
//                             playlistStateId={p.playlistStateId}
//                             id={p.state.referenceId}
//                             item={item}
//                             result={result}
//                             playlistId={playlistId ?? ""}
//                             isOptional={isOptional ?? false}
//                             transition={transition}
//                             elementIsReference={elementIsReference}
//                             nodeIsDone={nodeIsDone ?? false}
//                             warning={warning ?? false}
//                             state={state}
//                             closeDimmer={togglePreview}
//                             loadPlaylistStates={loadPlaylistStates}
//                         />
//                     }
//                     {type === "document" &&
//                         <PlaylistFileEntryDownloadDimmer
//                             playlistStateId={p.playlistStateId}
//                             id={p.state.referenceId}
//                             item={item}
//                             result={result}
//                             playlistId={playlistId ?? ""}
//                             isOptional={isOptional ?? false}
//                             transition={transition}
//                             elementIsReference={elementIsReference}
//                             nodeIsDone={nodeIsDone ?? false}
//                             warning={warning ?? false}
//                             state={state}
//                             closeDimmer={togglePreview}
//                             loadPlaylistStates={loadPlaylistStates}

//                         />
//                     }
//                 </>
//             }
//             {/*showModal && <PlaylistTubePodcastDimmer
//             playlistStateId={p.playlistStateId}
//             id={p.state.referenceId}
//             item={item}
//             link={link ?? ""}
//             result={result}
//             playlistId={playlistId ?? ""}
//             isOptional={isOptional ?? false}
//             transition={transition}
//             elementIsReference={elementIsReference}
//             nodeIsDone={nodeIsDone ?? false}
//             warning={warning ?? false}
//             state={state}
//             closeDimmer={togglePreview}
//         />
//         */}
//         </T.PLItemContainer>
//     );
// }


const PlaylistDetailNodeItem = (p: PlaylistDetailNodeItemProps<PostDownloadDto>) => {
    const { playlistContent, item, accent, ...subProps } = p;

    switch (item.type) {
        case "WikiArticle":
            return <WikiArticlePlaylistNodeItem item={item as WikiArticleDownloadDto} {...subProps} />;
        case "TubePost":
            return <TubePostPlaylistNodeItem item={item as TubePostDownloadDto} {...subProps} />;
        case "PodcastPost":
            return <PodcastPostPlaylistNodeItem item={item as PodcastPostDownloadDto} {...subProps} />;
        case "Quiz":
            return <QuizPlaylistNodeItem item={item as QuizDownloadDto} {...subProps} />;
        case "ContentPost":
            return <ContentPostPlaylistNodeItem item={item as ContentPostDownloadDto} {...subProps} />;
        case "BestPracticePost":
            return <BestPracticePlaylistPostNodeItem item={item as BestPracticePostDownloadDto} {...subProps} />;
        case "PorscheMomentPost":
            return <PorscheMomentPostPlaylistNodeItem item={item as PorscheMomentPostDownloadDto} {...subProps} />;
        case "FileEntry":
            return <FileEntryPlaylistNodeItem item={item as FileEntryDownloadDto as any} {...subProps} />
        case "PALMS":
            return <PALMSPlaylistNodeItem item={item as FileEntryDownloadDto as any} content={playlistContent!} {...subProps} />
        default:
            return <div >{item.type}</div>;
    }
}

export default PlaylistDetailNodeItem;